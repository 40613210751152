<template>
  <div>
    <div v-if="files.length">
      <b-card
        class="my-2 invoice-preview-card"
      >
        <b-row class="invoice-preview">
          <b-col
            cols="3"
            xl="2"
            md="2"
            sm="3"
            lg="2"
            class="text-left d-block my-auto"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="link"
              :disabled="currentFileIndex == 1"
              @click="changeFileIndex(currentFileIndex - 1)"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                class="pt-25"
                size="18"
              />
              <span class="align-middle">Prev</span>
            </b-button>
          </b-col>
          <b-col
            cols="6"
            xl="8"
            md="8"
            sm="6"
            lg="8"
            class="text-center"
          >
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              split
            >
              <template #button-content>
                <feather-icon
                  icon="FileIcon"
                  size="18"
                  class="align-middle mr-1"
                  :class="{'step-mobile__font-adjust' : isMobile()}"
                />
                <span
                  class="mr-1"
                  :class="{'step-mobile__font-adjust' : isMobile()}"
                >{{ currentFileIndex.toString().padStart(2, "0") }} / {{ files ? ((files.length + 1).toString().padStart(2, "0")) : '01' }}
                  <span v-if="!isMobile()">: {{ selectedFileName }}</span>
                </span>
              </template>
              <b-dropdown-item @click="changeFileIndex(1)">
                Ancestral Tablets Engraving Notice 牌位刻碑表格
              </b-dropdown-item>
              <b-dropdown-item
                v-for="(file, key) in files"
                :key="key"
                @click="changeFileIndex(key + 2)"
              >
                {{ file ? file.name : '' }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col
            cols="3"
            xl="2"
            md="2"
            sm="3"
            lg="2"
            class="text-right d-block my-auto"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="link"
              :disabled="currentFileIndex == (files ? (files.length + 1) : 1)"
              @click="changeFileIndex(currentFileIndex + 1)"
            >
              <span class="align-middle">Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                class="pt-25"
                size="18"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-card
      v-show="showAttachment"
      no-body
      class="invoice-preview-card"
    >
      <div class="m-2 card__inner p-4">
        <iframe
          v-show="showPDF"
          id="attachmentpdf"
          width="100%"
          height="1145"
        />
        <b-img
          v-show="!showPDF"
          :src="imageSrc"
          fluid-grow
          alt="Fluid-grow image"
        />
      </div>
    </b-card>
    <b-card
      v-show="!showAttachment"
      no-body
      class="invoice-preview-card ancestral-engraving-card mb-0"
    >
      <div
        class="urn-form-wrapper m-2"
        :class="{'card__inner p-4': (!isMobile() && (ownEngraving || secondOwnEngraving))}"
      >
        <div class="d-form-header d-flex justify-content-between mb-75">
          <div class="d-form-logo_section" />
          <div class="d-form-no_section text-right">
            <h5>
              {{ digitalForm.formNumber }}
            </h5>
            <h5>
              {{ digitalForm.revision }}
            </h5>
          </div>
        </div>
        <div class="urn-repository-order_section">
          <div class="b-form-section-title my-2">
            <h3>ANCESTRAL TABLETS ENGRAVING NOTICE <span>牌位刻碑表格</span></h3>
          </div>
          <b-row>
            <b-col
              lg="8"
              md="8"
              sm="8"
              cols="12"
              class="pr-0"
              :class="{'disabled-div': ownEngraving || secondOwnEngraving}"
            >
              <div class="d-form-border my-2 d-form-border my-2">
                <b-row>
                  <b-col
                    lg="7"
                    md="7"
                    sm="7"
                    cols="12"
                    class="d-flex justify-content-center"
                    :class="{ 'pr-0 an-padding-right-1': isMobile() }"
                  >
                    <div
                      class="p-1 padding-none"
                      :class="{'ml-1': !isMobile()}"
                    >
                      <h6 class="ancestral-table-section-title">
                        家族中堂
                      </h6>
                      <div class="d-flex">
                        <div class="vertical-single-box-wrapper">
                          <div
                            class="vertical-single-box bg-white"
                            :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-adjust' : '')]"
                          >
                            <div
                              v-if="secondInput || secondInputImage"
                              class="vertical-single-box-content"
                            >
                              <p v-if="secondLiving">
                                长生
                              </p>
                              <h5
                                v-if="secondInputType == 'input'"
                                ref="secondInputField"
                                :style="{ 'font-size': getFontSize('secondInputField') }"
                                :class="{ 'chinese-width': hasChineseText(secondInput) }"
                                @click="handleClick('secondInput', 'firstPartRef')"
                              >
                                {{ secondInput }}
                              </h5>
                              <div
                                v-else
                                :class="{ 'signed': secondInputImage }"
                              >
                                <b-img
                                  v-if="secondInputImage"
                                  :src="secondInputImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                              <p>2</p>
                            </div>
                            <div
                              v-else
                              class="full-width-div"
                              @click="handleClick('secondInput', 'firstPartRef')"
                            >
                              <div class="vertical-single-box-content-empty" />
                            </div>
                          </div>
                          <div
                            class="vertical-single-box bg-white"
                            :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-adjust' : '')]"
                          >
                            <div
                              v-if="fourthInput || fourthInputImage"
                              class="vertical-single-box-content"
                            >
                              <p v-if="fourthLiving">
                                长生
                              </p>
                              <h5
                                v-if="fourthInputType == 'input'"
                                ref="fourthInputField"
                                :style="{ 'font-size': getFontSize('fourthInputField') }"
                                :class="{ 'chinese-width': hasChineseText(fourthInput) }"
                                @click="handleClick('fourthInput', 'firstPartRef')"
                              >
                                {{ fourthInput }}
                              </h5>
                              <div
                                v-else
                                :class="{ 'signed': fourthInputImage }"
                              >
                                <b-img
                                  v-if="fourthInputImage"
                                  :src="fourthInputImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                              <p>4</p>
                            </div>
                            <div
                              v-else
                              class="full-width-div"
                              @click="handleClick('fourthInput', 'firstPartRef')"
                            >
                              <div class="vertical-single-box-content-empty" />
                            </div>
                          </div>
                          <div
                            class="vertical-single-box bg-white"
                            :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-adjust' : '')]"
                          >
                            <div
                              v-if="sixthInput || sixthInputImage"
                              class="vertical-single-box-content"
                            >
                              <p v-if="sixthLiving">
                                长生
                              </p>
                              <h5
                                v-if="sixthInputType == 'input'"
                                ref="sixthInputField"
                                :style="{ 'font-size': getFontSize('sixthInputField') }"
                                :class="{ 'chinese-width': hasChineseText(sixthInput) }"
                                @click="handleClick('sixthInput', 'firstPartRef')"
                              >
                                {{ sixthInput }}
                              </h5>
                              <div
                                v-else
                                :class="{ 'signed': sixthInputImage }"
                              >
                                <b-img
                                  v-if="sixthInputImage"
                                  :src="sixthInputImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                              <p>6</p>
                            </div>
                            <div
                              v-else
                              class="full-width-div"
                              @click="handleClick('sixthInput', 'firstPartRef')"
                            >
                              <div class="vertical-single-box-content-empty" />
                            </div>
                          </div>
                        </div>
                        <div class="vertical-single-box-wrapper-with-text">
                          <div class="vertical-single-box-heading">
                            <h4>佛光</h4>
                            <h4>接引</h4>
                          </div>
                          <div
                            class="vertical-single-box-with-text bg-white"
                            :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-adjust' : '')]"
                          >
                            <div
                              v-if="seventhInput || seventhInputImage"
                              class="vertical-single-box-content"
                            >
                              <p v-if="seventhLiving">
                                长生
                              </p>
                              <h5
                                v-if="seventhInputType == 'input'"
                                ref="seventhInputField"
                                :style="{ 'font-size': getFontSize('seventhInputField') }"
                                :class="{ 'chinese-width': hasChineseText(seventhInput) }"
                                @click="handleClick('seventhInput', 'firstPartRef')"
                              >
                                {{ seventhInput }}
                              </h5>
                              <div
                                v-else
                                :class="{ 'signed': seventhInputImage }"
                              >
                                <b-img
                                  v-if="seventhInputImage"
                                  :src="seventhInputImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                              <p>7</p>
                            </div>
                            <div
                              v-else
                              class="full-width-div"
                              @click="handleClick('seventhInput', 'firstPartRef')"
                            >
                              <div class="vertical-single-box-content-empty" />
                            </div>
                          </div>
                          <div class="vertical-single-box-heading-bottom">
                            <h4>門</h4>
                            <h4>歷</h4>
                            <h4>代</h4>
                            <h4>祖</h4>
                            <h4>先</h4>
                            <h4>位</h4>
                          </div>
                        </div>
                        <div class="vertical-single-box-wrapper">
                          <div
                            class="vertical-single-box bg-white"
                            :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-adjust' : '')]"
                          >
                            <div
                              v-if="firstInput || firstInputImage"
                              class="vertical-single-box-content"
                            >
                              <p v-if="firstLiving">
                                长生
                              </p>
                              <h5
                                v-if="firstInputType == 'input'"
                                ref="firstInputField"
                                :style="{ 'font-size': getFontSize('firstInputField') }"
                                :class="{ 'chinese-width': hasChineseText(firstInput) }"
                                @click="handleClick('firstInput', 'firstPartRef')"
                              >
                                {{ firstInput }}
                              </h5>
                              <div
                                v-else
                                :class="{ 'signed': firstInputImage }"
                              >
                                <b-img
                                  v-if="firstInputImage"
                                  :src="firstInputImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                              <p>1</p>
                            </div>
                            <div
                              v-else
                              class="full-width-div"
                              @click="handleClick('firstInput', 'firstPartRef')"
                            >
                              <div class="vertical-single-box-content-empty" />
                            </div>
                          </div>
                          <div
                            class="vertical-single-box bg-white"
                            :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-adjust' : '')]"
                          >
                            <div
                              v-if="thirdInput || thirdInputImage"
                              class="vertical-single-box-content"
                            >
                              <p v-if="thirdLiving">
                                长生
                              </p>
                              <h5
                                v-if="thirdInputType == 'input'"
                                ref="thirdInputField"
                                :style="{ 'font-size': getFontSize('thirdInputField') }"
                                :class="{ 'chinese-width': hasChineseText(thirdInput) }"
                                @click="handleClick('thirdInput', 'firstPartRef')"
                              >
                                {{ thirdInput }}
                              </h5>
                              <div
                                v-else
                                :class="{ 'signed': thirdInputImage }"
                              >
                                <b-img
                                  v-if="thirdInputImage"
                                  :src="thirdInputImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                              <p>3</p>
                            </div>
                            <div
                              v-else
                              class="full-width-div"
                              @click="handleClick('thirdInput', 'firstPartRef')"
                            >
                              <div class="vertical-single-box-content-empty" />
                            </div>
                          </div>
                          <div
                            class="vertical-single-box bg-white"
                            :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-adjust' : '')]"
                          >
                            <div
                              v-if="fifthInput || fifthInputImage"
                              class="vertical-single-box-content"
                            >
                              <p v-if="fifthLiving">
                                长生
                              </p>
                              <h5
                                v-if="fifthInputType == 'input'"
                                ref="fifthInputField"
                                :style="{ 'font-size': getFontSize('fifthInputField') }"
                                :class="{ 'chinese-width': hasChineseText(fifthInput) }"
                                @click="handleClick('fifthInput', 'firstPartRef')"
                              >
                                {{ fifthInput }}
                              </h5>
                              <div
                                v-else
                                :class="{ 'signed': fifthInputImage }"
                              >
                                <b-img
                                  v-if="fifthInputImage"
                                  :src="fifthInputImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                              <p>5</p>
                            </div>
                            <div
                              v-else
                              class="full-width-div"
                              @click="handleClick('fifthInput', 'firstPartRef')"
                            >
                              <div class="vertical-single-box-content-empty" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    class="vertical-box-right-section-block d-flex justify-content-center "
                    :class="{ 'pl-0' : !isMobile() }"
                    lg="5"
                    md="5"
                    sm="5"
                    cols="12"
                  >
                    <div class="p-1 padding-none padding-10 vertical-box-right-section__border-top">
                      <h6 class="ancestral-table-section-title">
                        無中堂
                      </h6>
                      <div class="vertical-single-box-right-section-block">
                        <div class="vertical-single-box-right-section-heading">
                          <h4>佛光</h4>
                          <h4>接引</h4>
                        </div>
                        <div class="vertical-single-box-right-section-main-wrapper">
                          <div class="vertical-single-box-right-section-wrapper">
                            <div
                              class="vertical-single-box-right-section bg-white"
                              :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-right-section-adjust' : '')]"
                            >
                              <div
                                v-if="secondInputSecond || secondInputImageSecond"
                                class="vertical-single-box-content"
                              >
                                <p v-if="secondLivingSecond">
                                  长生
                                </p>
                                <h5
                                  v-if="secondInputTypeSecond == 'input'"
                                  ref="secondInputFieldSecond"
                                  :style="{ 'font-size': getFontSize('secondInputFieldSecond') }"
                                  :class="{ 'chinese-width': hasChineseText(secondInputSecond) }"
                                  @click="handleClick('secondInputSecond', 'secondPartRef')"
                                >
                                  {{ secondInputSecond }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': secondInputImageSecond }"
                                >
                                  <b-img
                                    v-if="secondInputImageSecond"
                                    :src="secondInputImageSecond"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>2</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                                @click="handleClick('secondInputSecond', 'secondPartRef')"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                            <div
                              class="vertical-single-box-right-section bg-white"
                              :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-right-section-adjust' : '')]"
                            >
                              <div
                                v-if="fourthInputSecond || fourthInputImageSecond"
                                class="vertical-single-box-content"
                              >
                                <p v-if="fourthLivingSecond">
                                  长生
                                </p>
                                <h5
                                  v-if="fourthInputTypeSecond == 'input'"
                                  ref="fourthInputFieldSecond"
                                  :style="{ 'font-size': getFontSize('fourthInputFieldSecond') }"
                                  :class="{ 'chinese-width': hasChineseText(fourthInputSecond) }"
                                  @click="handleClick('fourthInputSecond', 'secondPartRef')"
                                >
                                  {{ fourthInputSecond }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': fourthInputImageSecond }"
                                >
                                  <b-img
                                    v-if="fourthInputImageSecond"
                                    :src="fourthInputImageSecond"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>4</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                                @click="handleClick('fourthInputSecond', 'secondPartRef')"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                            <div
                              class="vertical-single-box-right-section bg-white"
                              :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-right-section-adjust' : '')]"
                            >
                              <div
                                v-if="sixthInputSecond || sixthInputImageSecond"
                                class="vertical-single-box-content"
                              >
                                <p v-if="sixthLivingSecond">
                                  长生
                                </p>
                                <h5
                                  v-if="sixthInputTypeSecond == 'input'"
                                  ref="sixthInputFieldSecond"
                                  :style="{ 'font-size': getFontSize('sixthInputFieldSecond') }"
                                  :class="{ 'chinese-width': hasChineseText(sixthInputSecond) }"
                                  @click="handleClick('sixthInputSecond', 'secondPartRef')"
                                >
                                  {{ sixthInputSecond }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': sixthInputImageSecond }"
                                >
                                  <b-img
                                    v-if="sixthInputImageSecond"
                                    :src="sixthInputImageSecond"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>6</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                                @click="handleClick('sixthInputSecond', 'secondPartRef')"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                          </div>
                          <div class="vertical-single-box-right-section-wrapper">
                            <div
                              class="vertical-single-box-right-section bg-white"
                              :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-right-section-adjust' : '')]"
                            >
                              <div
                                v-if="firstInputSecond || firstInputImageSecond"
                                class="vertical-single-box-content"
                              >
                                <p v-if="firstLivingSecond">
                                  长生
                                </p>
                                <h5
                                  v-if="firstInputTypeSecond == 'input'"
                                  ref="firstInputFieldSecond"
                                  :style="{ 'font-size': getFontSize('firstInputFieldSecond') }"
                                  :class="{ 'chinese-width': hasChineseText(firstInputSecond) }"
                                  @click="handleClick('firstInputSecond', 'secondPartRef')"
                                >
                                  {{ firstInputSecond }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': firstInputImageSecond }"
                                >
                                  <b-img
                                    v-if="firstInputImageSecond"
                                    :src="firstInputImageSecond"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>1</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                                @click="handleClick('firstInputSecond', 'secondPartRef')"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                            <div
                              class="vertical-single-box-right-section bg-white"
                              :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-right-section-adjust' : '')]"
                            >
                              <div
                                v-if="thirdInputSecond || thirdInputImageSecond"
                                class="vertical-single-box-content"
                              >
                                <p v-if="thirdLivingSecond">
                                  长生
                                </p>
                                <h5
                                  v-if="thirdInputTypeSecond == 'input'"
                                  ref="thirdInputFieldSecond"
                                  :style="{ 'font-size': getFontSize('thirdInputFieldSecond') }"
                                  :class="{ 'chinese-width': hasChineseText(thirdInputSecond) }"
                                  @click="handleClick('thirdInputSecond', 'secondPartRef')"
                                >
                                  {{ thirdInputSecond }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': thirdInputImageSecond }"
                                >
                                  <b-img
                                    v-if="thirdInputImageSecond"
                                    :src="thirdInputImageSecond"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>3</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                                @click="handleClick('thirdInputSecond', 'secondPartRef')"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                            <div
                              class="vertical-single-box-right-section bg-white"
                              :class="[((ownEngraving && !isMobile()) ? 'vertical-single-box-right-section-adjust' : '')]"
                            >
                              <div
                                v-if="fifthInputSecond || fifthInputImageSecond"
                                class="vertical-single-box-content"
                              >
                                <p v-if="fifthLivingSecond">
                                  长生
                                </p>
                                <h5
                                  v-if="fifthInputTypeSecond == 'input'"
                                  ref="fifthInputFieldSecond"
                                  :style="{ 'font-size': getFontSize('fifthInputFieldSecond') }"
                                  :class="{ 'chinese-width': hasChineseText(fifthInputSecond) }"
                                  @click="handleClick('fifthInputSecond', 'secondPartRef')"
                                >
                                  {{ fifthInputSecond }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': fifthInputImageSecond }"
                                >
                                  <b-img
                                    v-if="fifthInputImageSecond"
                                    :src="fifthInputImageSecond"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>5</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                                @click="handleClick('fifthInputSecond', 'secondPartRef')"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-single-box-right-section-heading-bottom">
                          <h4>之</h4>
                          <h4>蓮</h4>
                          <h4>位</h4>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              lg="4"
              md="4"
              sm="4"
              cols="12"
            >
              <div class="d-form-border my-2 d-form-border my-2">
                <div class="d-form-photo-status-block">
                  <div class="d-form-heading-block">
                    <h4>
                      Photo Received
                      <br>
                      <span>照片提供</span>
                    </h4>
                  </div>
                  <b-form-group
                    label-class="ancestral-label-adjust"
                    label="Received By 收件:"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text ancestral-input-adjust bg-white"
                    >
                      <p>
                        -
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group
                    label-class="ancestral-label-adjust"
                    label="Date 日期:"
                    label-for="input-default"
                    class="form-group-margin"
                  >
                    <div
                      class="empty-input__text ancestral-input-adjust bg-white"
                    >
                      <p>
                        -
                      </p>
                    </div>
                  </b-form-group>
                  <div class="d-form-heading-block">
                    <h4>
                      Photo Returned
                      <br>
                      <span>照片归还</span>
                    </h4>
                  </div>
                  <b-form-group
                    label-class="ancestral-label-adjust"
                    label="Claimed By 照片领回:"
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text ancestral-input-adjust bg-white"
                    >
                      <p>
                        -
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group
                    label-class="ancestral-label-adjust"
                    label="Date 日期:"
                    label-for="input-default"
                    class="form-group-margin"
                  >
                    <div
                      class="empty-input__text ancestral-input-adjust bg-white"
                    >
                      <p>
                        -
                      </p>
                    </div>
                  </b-form-group>
                  <div class="d-form-heading-block">
                    <h4>Remarks 备注</h4>
                  </div>
                  <div class="ancestral-form-remarks">
                    <p>All Chinese Character on the plaque must be in traditional Chinese text.<br><span>碑文均採繁體</span></p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              sm="12"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>Purchaser*</span>
                  <br>
                  <span>购买者</span>
                </template>
                <div
                  v-if="customerNameType == 'input'"
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ customerName || '-' }}
                  </p>
                </div>
                <div
                  v-else
                  :class="{ 'signed': customerNameImage }"
                  style="border-bottom: 1px solid #000"
                >
                  <b-img
                    v-if="customerNameImage"
                    :src="customerNameImage"
                    class="w-100 signature-image"
                    alt="sign"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>Contact No.</span>
                  <br>
                  <span>联络号码</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ customerContact || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>NRIC*</span>
                  <br>
                  <span>身分证号码</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ customerNRIC || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              sm="12"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="3"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>Next of Kin Name*</span>
                  <br>
                  <span>至亲姓名</span>
                </template>
                <div
                  v-if="currentKinNameType == 'input'"
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ kinName || '-' }}
                  </p>
                </div>
                <div
                  v-else
                  :class="{ 'signed': kinNameImage }"
                  style="border-bottom: 1px solid #000"
                >
                  <b-img
                    v-if="kinNameImage"
                    :src="kinNameImage"
                    class="w-100 signature-image"
                    alt="sign"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>Contact No.</span>
                  <br>
                  <span>联络号码</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ kinContact || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>NRIC*</span>
                  <br>
                  <span>身分证号码</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ kinNRIC || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>File No.*</span>
                  <br>
                  <span>文件号码</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ fileNo || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>Pedestal No.*</span>
                  <br>
                  <span>牌位编号</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ pedestalNo || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>Agent Name*</span>
                  <br>
                  <span>代理姓名</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ agentName || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>Agent Contact No.</span>
                  <br>
                  <span>代理联络电话</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ agentContact || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              sm="12"
              cols="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-class=""
                label-for="input-default"
                class="small-device-f-group-adjust align-items-center"
              >
                <template #label>
                  <span>Remarks</span>
                  <br>
                  <span>备注</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ remarks || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-form-disclaimer_text-block">
            <p>Disclaimer: I hereby confirm that all the information and characters herein are correct and authorised Mount Prajna Ltd to engrave the Plaque in accordance to this notice. The Plaque will be available after 30 days from the date of this notice.</p>
            <p>声明： 本人确定所附资料正确无误，并请遵照刻碑，本表格签署三十天后，即请安排上碑。</p>
          </div>
          <b-row
            ref="previewSignDiv"
            class="d-form-signature_block"
          >
            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <p>Purchaser’s / Next of Kin’s Signatory & Date
                <br>
                <span>购者或至亲签名</span>
              </p>
              <div
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.purchaser-sign-modal-preview
                class="form-signature-box"
                :class="{'signed': customerSignImage, 'form-signature-box__focus' : !customerSignImage && signboxFocus}"
                :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
              >
                <div class="form-signature-content">
                  <span
                    v-if="!customerSignImage"
                    class="form-signature-text"
                  >
                    Sign here
                  </span>
                  <b-img
                    v-else
                    :src="customerSignImage"
                    alt="sign"
                  />
                </div>
              </div>
              <div class="signature-date-block">
                <p>Name 姓名: {{ customerName }}</p>
                <p v-if="customerSignImage">
                  Date 日期: {{ dateFormatWithTime() }}
                </p>
                <p v-else>
                  Date 日期:
                </p>
              </div>
            </b-col>
            <b-col
              md="6"
              sm="6"
              cols="12"
            >
              <p>Completion checked
                <br>
                <span>碑文检查，确认签名 / 日期</span>
              </p>
              <div
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="form-signature-box signed"
              >
                <div class="form-signature-content" />
              </div>
              <div class="signature-date-block">
                <p>Name 姓名: </p>
                <p>Date 日期: </p>
              </div>
            </b-col>
          </b-row>
          <div class="d-form-border mt-4 mobile_res-bottom-box">
            <h5 class="d-form-office-use-title">
              For office use 公务用栏
            </h5>
            <b-row>
              <b-col
                md="2"
                sm="4"
                cols="5"
                class="d-form-border-right pr-0"
              >
                <div class="padding-20">
                  <b-form-group
                    label="Remark 备注:"
                    label-for="input-default"
                    label-class="col-form-label "
                    class="small-device-f-group-adjust"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        -
                      </p>
                    </div>
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                md="5"
                sm="4"
                cols="7"
                class="person-in__charge-border"
                :class="{ 'p-0' : !isMobile(), 'pl-0' : isMobile() }"
              >
                <div class="padding-20">
                  <b-form-group
                    label="Person in Charge 服务人员:"
                    label-for="input-default"
                    label-class="col-form-label "
                    class="small-device-f-group-adjust"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        -
                      </p>
                    </div>
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                md="5"
                sm="4"
                cols="12"
                :class="{ 'pl-0' : !isMobile() }"
              >
                <div class="installation-date__border padding-20">
                  <b-form-group
                    label="Installation Date 预定上位日期:"
                    label-for="input-default"
                    label-class="col-form-label "
                    class="small-device-f-group-adjust"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        -
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group
                    label="Submitted Date 刻碑资料提交日期:"
                    label-for="input-default"
                    label-class="col-form-label "
                    class="small-device-f-group-adjust padding-top-6"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        -
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group
                    label="Email Date 校对检查电邮日期:"
                    label-for="input-default"
                    label-class="col-form-label "
                    class="small-device-f-group-adjust padding-top-6"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        -
                      </p>
                    </div>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>

    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal-preview"
      ref="purchaser-sign-modal-preview"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser / Authorized Representative Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePadPReview"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
// eslint-disable-next-line
  import 'quill/dist/quill.core.css'
// eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BButton,
    BDropdown,
    BDropdownItem,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    pedestalFormValues: {
      type: Array,
      required: false,
      default: () => [],
    },
    requestForm: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
      required: false,
      default: () => [],
    },
    signboxFocus: {
      type: Boolean,
      required: true,
    },
    formIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isAncestralSidebarActive: false,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 20,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
      },
      uploadedFiles: [],
      need: [],
      needOptions: [
        { text: 'As-Need', value: 'as-need' },
        { text: 'Pre-Needed', value: 'pre-needed' },
      ],
      formStatus: 'To prepare',
      isInputValue: true,
      ownEngraving: '',
      secondOwnEngraving: '',
      firstLiving: '',
      secondLiving: '',
      thirdLiving: '',
      fourthLiving: '',
      fifthLiving: '',
      sixthLiving: '',
      seventhLiving: '',
      firstLivingSecond: '',
      secondLivingSecond: '',
      thirdLivingSecond: '',
      fourthLivingSecond: '',
      fifthLivingSecond: '',
      sixthLivingSecond: '',
      firstInputType: 'input',
      secondInputType: 'input',
      thirdInputType: 'input',
      fourthInputType: 'input',
      fifthInputType: 'input',
      sixthInputType: 'input',
      seventhInputType: 'input',
      firstInputTypeSecond: 'input',
      secondInputTypeSecond: 'input',
      thirdInputTypeSecond: 'input',
      fourthInputTypeSecond: 'input',
      fifthInputTypeSecond: 'input',
      sixthInputTypeSecond: 'input',
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: '',
      fifthInput: '',
      sixthInput: '',
      seventhInput: '',
      firstInputSecond: '',
      secondInputSecond: '',
      thirdInputSecond: '',
      fourthInputSecond: '',
      fifthInputSecond: '',
      sixthInputSecond: '',
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      completionSign: false,
      completionSignSrc: '',
      completionSignDate: '',
      completionSignName: '',
      firstInputImageError: false,
      secondInputImageError: false,
      thirdInputImageError: false,
      fourthInputImageError: false,
      fifthInputImageError: false,
      sixthInputImageError: false,
      seventhInputImageError: false,
      firstInputImageSecondError: false,
      secondInputImageSecondError: false,
      thirdInputImageSecondError: false,
      fourthInputImageSecondError: false,
      fifthInputImageSecondError: false,
      sixthInputImageSecondError: false,
      purchaserDetailsCollapse: true,
      othersDetailsCollapse: true,
      firstPartCollapse: true,
      secondPartCollapse: true,
      particularCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePadPReview.resizeCanvas() },
      },
      nameInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.nameInputPad.resizeCanvas() },
      },
      secondNameInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.secondNameInputPad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePadPReview.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePadPReview.resizeCanvas() },
      },
      thirdInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.thirdInputPad.resizeCanvas() },
      },
      fourthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fourthInputPad.resizeCanvas() },
      },
      fifthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fifthInputPad.resizeCanvas() },
      },
      sixthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.sixthInputPad.resizeCanvas() },
      },
      seventhInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.seventhInputPad.resizeCanvas() },
      },
      firstInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.firstInputSecondPad.resizeCanvas() },
      },
      secondInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.secondInputSecondPad.resizeCanvas() },
      },
      thirdInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.thirdInputSecondPad.resizeCanvas() },
      },
      fourthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fourthInputSecondPad.resizeCanvas() },
      },
      fifthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fifthInputSecondPad.resizeCanvas() },
      },
      sixthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.sixthInputSecondPad.resizeCanvas() },
      },
      currentFirstInputType: 'input',
      customerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNameImageError: false,
      firstInputImage: '',
      secondInputImage: '',
      thirdInputImage: '',
      fourthInputImage: '',
      fifthInputImage: '',
      sixthInputImage: '',
      seventhInputImage: '',
      firstInputImageSecond: '',
      secondInputImageSecond: '',
      thirdInputImageSecond: '',
      fourthInputImageSecond: '',
      fifthInputImageSecond: '',
      sixthInputImageSecond: '',
      customerNRIC: '',
      customerContact: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNameImageError: false,
      kinNRIC: '',
      kinContact: '',
      fileNo: '',
      pedestalNo: '',
      agentName: '',
      agentContact: '',
      remarks: '',
      personInCharge: '',
      installationDate: '',
      installationDateLocal: '',
      inChargePerson: '',
      inChargePersonLocal: '',
      submitDate: '',
      emailDate: '',
      officeRemarks: '',
      serviceType: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      serviceTypeValue: [],
      serviceTypeValueLocal: [],
      existingCompletionSignSrcObject: {},
      existingPurchaserSignObject: {},
      existingServiceTypeObject: {},
      existingInChargePersonObject: {},
      ownEngravingImage: [],
      secondOwnEngravingImage: [],
      selectedFileName: 'Ancestral Tablets Engraving Notice 牌位刻碑表格',
      currentFileIndex: 1,
      imageSrc: '',
      showPDF: true,
      showAttachment: false,
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Compose message',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Compose message',
      },
      isSignature: false,
      defaultSignature: '',
      currentField: '',
      currentSecondField: '',
      customerSignImage: '',
      showFileUploadError: false,
      required,
    }
  },
  computed: {
    requiredRuleComputation() {
      if (this.ownEngraving || this.secondOwnEngraving) {
        return ''
      }
      if (!this.firstInput && !this.secondInput && !this.thirdInput && !this.fourthInput && !this.fifthInput && !this.sixthInput && !this.seventhInput
        && !this.firstInputImage && !this.secondInputImage && !this.thirdInputImage && !this.fourthInputImage && !this.fifthInputImage && !this.sixthInputImage && !this.seventhInputImage
        && !this.firstInputSecond && !this.secondInputSecond && !this.thirdInputSecond && !this.fourthInputSecond && !this.fifthInputSecond && !this.sixthInputSecond
        && !this.firstInputImageSecond && !this.secondInputImageSecond && !this.thirdInputImageSecond && !this.fourthInputImageSecond && !this.fifthInputImageSecond && !this.sixthInputImageSecond) {
        return 'required'
      }

      return ''
    },
    requiredRuleComputationSecond() {
      if (this.ownEngraving || this.secondOwnEngraving) {
        return ''
      }
      if (!this.firstInputSecond && !this.secondInputSecond && !this.thirdInputSecond && !this.fourthInputSecond && !this.fifthInputSecond && !this.sixthInputSecond) {
        return 'required'
      }

      return ''
    },
  },
  created() {
    this.makeFormValues()
    // this.customerMobileVal = this.requestForm.customerContact
    // this.customerEmailVal = this.requestForm.customerEmail
  },
  methods: {
    async getFontSize(field) {
      if (process.env.VUE_APP_FONT_AUTO_ADJUST === 'true') {
        const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight
        let i = 21
        let overflow = true
        const minSize = 2
        await this.$nextTick()
        const element = this.$refs[field]
        if (element) {
          const parent = element.parentElement
          while (overflow && i > minSize) {
            element.style.fontSize = `${i}px`
            overflow = isOverflown(parent)
            // eslint-disable-next-line no-plusplus
            if (overflow) i--
          }
        }

        return `${i}px`
      }

      return '21px'
    },
    hasChineseText(text) {
      const initialLength = text.length
      const chineseLength = text.split('')
        .filter(char => (/\p{Script=Han}/u.test(char) || /\s/u.test(char)))
        .join('')
        .length
      if (initialLength === chineseLength) {
        return true
      }

      return false
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = 'Ancestral Tablets Engraving Notice 牌位刻碑表格'
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.pdfDataJS)
        // }
        // this.showFirst = false
        // const pdfViewerFrame = document.getElementById('docpdf2')
        // pdfViewerFrame.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.pdfDataJS}`)
        this.showAttachment = false
      } else {
        this.selectedFileName = this.files[key - 2].name
        if (this.files[key - 2].type.includes('pdf')) {
          const base64Raw = this.files[key - 2].data.split(',')[1]
          const pdfDataJS = this.base64ToUint8Array(base64Raw)
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          // pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${pdfDataJS}`)
          // eslint-disable-next-line func-names
          pdfViewerFrame2.onload = () => {
            pdfViewerFrame2.contentWindow.PDFViewerApplication.open(pdfDataJS)
          }
          pdfViewerFrame2.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          this.showPDF = true
        } else {
          this.imageSrc = this.files[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    closeCompletionSignModal() {
      this.$root.$emit('bv::hide::modal', 'completion-sign-modal', '')
    },
    clearCompletionSign() {
      this.$refs.signaturePadPReview.clearSignature()
    },
    saveCompletionSign() {
      const { data } = this.$refs.signaturePadPReview.saveSignature()
      if (data) {
        const formData = new FormData()
        const completionSignObj = {
          key: 'completionSignSrc',
          value: data,
        }
        formData.append('signature', JSON.stringify(completionSignObj))
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
              this.$root.$emit('bv::hide::modal', 'completion-sign-modal', '')
              this.makeFormValues()
              this.$swal({
                title: 'Signature Saved',
                html: response.data.message || '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    async makeFormValues() {
      if (this.pedestalFormValues && this.pedestalFormValues.length) {
        const ownEngravingObject = this.pedestalFormValues.find(o => o.key === 'ownEngraving')
        const secondOwnEngravingObject = this.pedestalFormValues.find(o => o.key === 'secondOwnEngraving')
        if (ownEngravingObject || secondOwnEngravingObject) {
          let hasValue = false
          if (ownEngravingObject && ownEngravingObject.value) {
            this.ownEngraving = ownEngravingObject.value
            hasValue = true
          }
          if (secondOwnEngravingObject && secondOwnEngravingObject.value) {
            this.secondOwnEngraving = secondOwnEngravingObject.value
            hasValue = true
          }
          if (!hasValue) {
            const firstLivingObject = this.pedestalFormValues.find(o => o.key === 'firstLiving')
            if (firstLivingObject) {
              this.firstLiving = firstLivingObject.value
            }
            const secondLivingObject = this.pedestalFormValues.find(o => o.key === 'secondLiving')
            if (secondLivingObject) {
              this.secondLiving = secondLivingObject.value
            }
            const thirdLivingObject = this.pedestalFormValues.find(o => o.key === 'thirdLiving')
            if (thirdLivingObject) {
              this.thirdLiving = thirdLivingObject.value
            }
            const fourthLivingObject = this.pedestalFormValues.find(o => o.key === 'fourthLiving')
            if (fourthLivingObject) {
              this.fourthLiving = fourthLivingObject.value
            }
            const fifthLivingObject = this.pedestalFormValues.find(o => o.key === 'fifthLiving')
            if (fifthLivingObject) {
              this.fifthLiving = fifthLivingObject.value
            }
            const sixthLivingObject = this.pedestalFormValues.find(o => o.key === 'sixthLiving')
            if (sixthLivingObject) {
              this.sixthLiving = sixthLivingObject.value
            }
            const seventhLivingObject = this.pedestalFormValues.find(o => o.key === 'seventhLiving')
            if (seventhLivingObject) {
              this.seventhLiving = seventhLivingObject.value
            }

            const firstInputObject = this.pedestalFormValues.find(o => o.key === 'firstInput')
            if (firstInputObject) {
              if (firstInputObject.value.startsWith('data:image/')) {
                this.firstInputImage = firstInputObject.value
                this.firstInputType = 'canvas'
              } else {
                this.firstInputType = 'input'
                this.firstInput = firstInputObject.value
              }
            }

            const secondInputObject = this.pedestalFormValues.find(o => o.key === 'secondInput')
            if (secondInputObject) {
              if (secondInputObject.value.startsWith('data:image/')) {
                this.secondInputImage = secondInputObject.value
                this.secondInputType = 'canvas'
              } else {
                this.secondInputType = 'input'
                this.secondInput = secondInputObject.value
              }
            }
            const thirdInputObject = this.pedestalFormValues.find(o => o.key === 'thirdInput')
            if (thirdInputObject) {
              if (thirdInputObject.value.startsWith('data:image/')) {
                this.thirdInputImage = thirdInputObject.value
                this.thirdInputType = 'canvas'
              } else {
                this.thirdInputType = 'input'
                this.thirdInput = thirdInputObject.value
              }
            }

            const fourthInputObject = this.pedestalFormValues.find(o => o.key === 'fourthInput')
            if (fourthInputObject) {
              if (fourthInputObject.value.startsWith('data:image/')) {
                this.fourthInputImage = fourthInputObject.value
                this.fourthInputType = 'canvas'
              } else {
                this.fourthInputType = 'input'
                this.fourthInput = fourthInputObject.value
              }
            }
            const fifthInputObject = this.pedestalFormValues.find(o => o.key === 'fifthInput')
            if (fifthInputObject) {
              if (fifthInputObject.value.startsWith('data:image/')) {
                this.fifthInputImage = fifthInputObject.value
                this.fifthInputType = 'canvas'
              } else {
                this.fifthInputType = 'input'
                this.fifthInput = fifthInputObject.value
              }
            }

            const sixthInputObject = this.pedestalFormValues.find(o => o.key === 'sixthInput')
            if (sixthInputObject) {
              if (sixthInputObject.value.startsWith('data:image/')) {
                this.sixthInputImage = sixthInputObject.value
                this.sixthInputType = 'canvas'
              } else {
                this.sixthInputType = 'input'
                this.sixthInput = sixthInputObject.value
              }
            }

            const seventhInputObject = this.pedestalFormValues.find(o => o.key === 'seventhInput')
            if (seventhInputObject) {
              if (seventhInputObject.value.startsWith('data:image/')) {
                this.seventhInputImage = seventhInputObject.value
                this.seventhInputType = 'canvas'
              } else {
                this.seventhInputType = 'input'
                this.seventhInput = seventhInputObject.value
              }
            }

            const firstLivingSecondObject = this.pedestalFormValues.find(o => o.key === 'firstLivingSecond')
            if (firstLivingSecondObject) {
              this.firstLivingSecond = firstLivingSecondObject.value
            }
            const secondLivingSecondObject = this.pedestalFormValues.find(o => o.key === 'secondLivingSecond')
            if (secondLivingSecondObject) {
              this.secondLivingSecond = secondLivingSecondObject.value
            }
            const thirdLivingSecondObject = this.pedestalFormValues.find(o => o.key === 'thirdLivingSecond')
            if (thirdLivingSecondObject) {
              this.thirdLivingSecond = thirdLivingSecondObject.value
            }
            const fourthLivingSecondObject = this.pedestalFormValues.find(o => o.key === 'fourthLivingSecond')
            if (fourthLivingSecondObject) {
              this.fourthLivingSecond = fourthLivingSecondObject.value
            }
            const fifthLivingSecondObject = this.pedestalFormValues.find(o => o.key === 'fifthLivingSecond')
            if (fifthLivingSecondObject) {
              this.fifthLivingSecond = fifthLivingSecondObject.value
            }
            const sixthLivingSecondObject = this.pedestalFormValues.find(o => o.key === 'sixthLivingSecond')
            if (sixthLivingSecondObject) {
              this.sixthLivingSecond = sixthLivingSecondObject.value
            }

            const firstInputSecondObject = this.pedestalFormValues.find(o => o.key === 'firstInputSecond')
            if (firstInputSecondObject) {
              if (firstInputSecondObject.value.startsWith('data:image/')) {
                this.firstInputImageSecond = firstInputSecondObject.value
                this.firstInputTypeSecond = 'canvas'
              } else {
                this.firstInputTypeSecond = 'input'
                this.firstInputSecond = firstInputSecondObject.value
              }
            }

            const secondInputSecondObject = this.pedestalFormValues.find(o => o.key === 'secondInputSecond')
            if (secondInputSecondObject) {
              if (secondInputSecondObject.value.startsWith('data:image/')) {
                this.secondInputImageSecond = secondInputSecondObject.value
                this.secondInputTypeSecond = 'canvas'
              } else {
                this.secondInputTypeSecond = 'input'
                this.secondInputSecond = secondInputSecondObject.value
              }
            }
            const thirdInputSecondObject = this.pedestalFormValues.find(o => o.key === 'thirdInputSecond')
            if (thirdInputSecondObject) {
              if (thirdInputSecondObject.value.startsWith('data:image/')) {
                this.thirdInputImageSecond = thirdInputSecondObject.value
                this.thirdInputTypeSecond = 'canvas'
              } else {
                this.thirdInputTypeSecond = 'input'
                this.thirdInputSecond = thirdInputSecondObject.value
              }
            }

            const fourthInputSecondObject = this.pedestalFormValues.find(o => o.key === 'fourthInputSecond')
            if (fourthInputSecondObject) {
              if (fourthInputSecondObject.value.startsWith('data:image/')) {
                this.fourthInputImageSecond = fourthInputSecondObject.value
                this.fourthInputTypeSecond = 'canvas'
              } else {
                this.fourthInputTypeSecond = 'input'
                this.fourthInputSecond = fourthInputSecondObject.value
              }
            }
            const fifthInputSecondObject = this.pedestalFormValues.find(o => o.key === 'fifthInputSecond')
            if (fifthInputSecondObject) {
              if (fifthInputSecondObject.value.startsWith('data:image/')) {
                this.fifthInputImageSecond = fifthInputSecondObject.value
                this.fifthInputTypeSecond = 'canvas'
              } else {
                this.fifthInputTypeSecond = 'input'
                this.fifthInputSecond = fifthInputSecondObject.value
              }
            }

            const sixthInputSecondObject = this.pedestalFormValues.find(o => o.key === 'sixthInputSecond')
            if (sixthInputSecondObject) {
              if (sixthInputSecondObject.value.startsWith('data:image/')) {
                this.sixthInputImageSecond = sixthInputSecondObject.value
                this.sixthInputTypeSecond = 'canvas'
              } else {
                this.sixthInputTypeSecond = 'input'
                this.sixthInputSecond = sixthInputSecondObject.value
              }
            }
          }
        }

        // this.files.forEach(arrayItem => {
        //   this.uploadedFiles.push(arrayItem)
        // })

        const customerNameObject = this.pedestalFormValues.find(o => o.key === 'customerName')
        if (customerNameObject) {
          if (customerNameObject.value.startsWith('data:image/')) {
            this.customerNameImage = customerNameObject.value
            this.customerNameType = 'canvas'
          } else {
            this.customerNameType = 'input'
            this.customerName = customerNameObject.value
          }
        }
        const customerNRICObject = this.pedestalFormValues.find(o => o.key === 'customerNRIC')
        if (customerNRICObject) {
          this.customerNRIC = customerNRICObject.value
        }
        const customerContactObject = this.pedestalFormValues.find(o => o.key === 'customerContact')
        if (customerContactObject) {
          this.customerContact = customerContactObject.value
        }
        const kinNameObject = this.pedestalFormValues.find(o => o.key === 'kinName')
        if (kinNameObject) {
          if (kinNameObject.value.startsWith('data:image/')) {
            this.kinNameImage = kinNameObject.value
            this.currentKinNameType = 'canvas'
          } else {
            this.currentKinNameType = 'input'
            this.kinName = kinNameObject.value
          }
        }
        const kinNRICObject = this.pedestalFormValues.find(o => o.key === 'kinNRIC')
        if (kinNRICObject) {
          this.kinNRIC = kinNRICObject.value
        }
        const kinContactObject = this.pedestalFormValues.find(o => o.key === 'kinContact')
        if (kinContactObject) {
          this.kinContact = kinContactObject.value
        }
        const fileNoObject = this.pedestalFormValues.find(o => o.key === 'fileNo')
        if (fileNoObject) {
          this.fileNo = fileNoObject.value
        }
        const pedestalNoObject = this.pedestalFormValues.find(o => o.key === 'pedestalNo')
        if (pedestalNoObject) {
          this.pedestalNo = pedestalNoObject.value
        }
        const agentNameObject = this.pedestalFormValues.find(o => o.key === 'agentName')
        if (agentNameObject) {
          this.agentName = agentNameObject.value
        }
        const agentContactObject = this.pedestalFormValues.find(o => o.key === 'agentContact')
        if (agentContactObject) {
          this.agentContact = agentContactObject.value
        }
        const remarksObject = this.pedestalFormValues.find(o => o.key === 'remarks')
        if (remarksObject) {
          this.remarks = remarksObject.value
        }
        const inChargePersonObject = this.pedestalFormValues.find(o => o.key === 'inChargePerson')
        if (inChargePersonObject) {
          this.existingInChargePersonObject = inChargePersonObject
          this.inChargePerson = inChargePersonObject.value
          this.inChargePersonLocal = inChargePersonObject.value
          this.inChargePersonVal = inChargePersonObject.value
        }
        const serviceTypeObject = this.pedestalFormValues.find(o => o.key === 'serviceType')
        if (serviceTypeObject) {
          this.existingServiceTypeObject = serviceTypeObject
          this.serviceType = serviceTypeObject.value
          this.serviceTypeValue = [serviceTypeObject.value]
          this.serviceTypeValueLocal = [serviceTypeObject.value]
        }
        const installationDateObject = this.pedestalFormValues.find(o => o.key === 'installationDate')
        if (installationDateObject) {
          this.installationDate = installationDateObject.value
          this.installationDateLocal = installationDateObject.value
        }
        const submitDateObject = this.pedestalFormValues.find(o => o.key === 'submitDate')
        if (submitDateObject) {
          this.submitDate = submitDateObject.value
        }
        const emailDateObject = this.pedestalFormValues.find(o => o.key === 'emailDate')
        if (emailDateObject) {
          this.emailDate = emailDateObject.value
        }
        const purchaserSignObject = this.pedestalFormValues.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject && purchaserSignObject.value) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.customerSignImage = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
      }
    },
    startSignProcess() {
      const finalStatus = 'Signing'
      const formData = new FormData()
      formData.append('status', finalStatus)
      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editForm() {
      if (this.requestForm.status === 'Not required') {
        const finalStatus = 'To prepare'
        const formData = new FormData()
        formData.append('status', finalStatus)
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.editFormInOtherStatus = true
      }
    },
    makeFormNotRequired() {
      this.$swal({
        title: 'Are you sure you want to ‘Mark as Not Required’ for this form?',
        html: 'The form status will change to ‘Not Required’, all information you have keyed in will be cleared.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: "Yes, I'm sure",
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', 'Not required')
            this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(async response => {
                if (response.data.data) {
                  await this.$emit('update:requestForm', response.data.data)
                  this.makeFormValues()
                  this.$swal({
                    title: 'Form Has Been Marked as Not Required',
                    // html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || '',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal-preview', '')
    },
    openPurchaserSignModal() {
      this.$root.$emit('bv::show::modal', 'purchaser-sign-modal-preview', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePadPReview.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePadPReview.saveSignature()

      if (data) {
        this.customerSignImage = data
        const purchaserSignObj = {
          key: 'purchaserSign',
          value: this.customerSignImage,
        }
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal-preview', '')
        const valueArray = this.pedestalFormValues
        const index = valueArray.findIndex(entry => entry.key === 'purchaserSign')
        if (index !== -1) {
          valueArray[index] = purchaserSignObj
        } else {
          valueArray.push(purchaserSignObj)
        }
        this.$emit('updateSignValidation', JSON.stringify(valueArray), this.formIndex)
      }
    },
    closeAuthorizedSignModal() {
      this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
    },
    clearAuthorizedSign() {
      this.$refs.signaturePadPReview.clearSignature()
    },
    saveAuthorizedSign() {
      const { data } = this.$refs.signaturePadPReview.saveSignature()

      if (data) {
        const formData = new FormData()
        const authorizedSignObj = {
          key: 'authorizedSignSrc',
          value: data,
        }
        formData.append('signature', JSON.stringify(authorizedSignObj))
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
              this.$root.$emit('bv::hide::modal', 'authorized-sign-modal', '')
              this.makeFormValues()
              this.$swal({
                title: 'Signature Saved',
                html: response.data.message || '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    async goToSignElement(pos = 'bottom') {
      await this.$nextTick()
      const element = this.$refs.previewSignDiv
      if (element) {
        if (pos === 'bottom') {
          const top = element.offsetTop
          window.scrollTo({
            top,
            behavior: 'smooth',
          })
        } else {
          window.scrollTo({
            top: 135,
            behavior: 'smooth',
          })
        }
      }
    },
    closeWitnessedSignModal() {
      this.$root.$emit('bv::hide::modal', 'witnessed-sign-modal', '')
    },
    closeOfficeSectionModal() {
      this.$root.$emit('bv::hide::modal', 'office-use-section-modal', '')
    },
    saveOfficeSectionData() {
      const formData = new FormData()
      const inChargePersonObject = {
        key: 'inChargePerson',
        value: this.inChargePersonLocal,
      }
      formData.append('inChargePersonObject', JSON.stringify(inChargePersonObject))

      const installationDateObject = {
        key: 'installationDate',
        value: this.installationDateLocal,
      }
      formData.append('installationDateObject', JSON.stringify(installationDateObject))

      const stVal = this.serviceTypeValueLocal[0] ? this.serviceTypeValueLocal[0] : ''
      const serviceTypeObject = {
        key: 'serviceType',
        value: stVal,
      }
      formData.append('serviceTypeObject', JSON.stringify(serviceTypeObject))
      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/office-section/ancestral`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
            this.$root.$emit('bv::hide::modal', 'office-use-section-modal', '')
            this.makeFormValues()
            this.$swal({
              title: 'Signature Saved',
              html: response.data.message || '',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/save.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              showCancelButton: false,
              confirmButtonText: 'Okay',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    clearWitnessedSign() {
      this.$refs.signaturePadPReview.clearSignature()
    },
    saveWitnessedSign() {
      const { data } = this.$refs.signaturePadPReview.saveSignature()

      if (data) {
        const formData = new FormData()
        const witnessedSignObj = {
          key: 'witnessedSignSrc',
          value: data,
        }
        formData.append('signature', JSON.stringify(witnessedSignObj))
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
              this.makeFormValues()
              this.$root.$emit('bv::hide::modal', 'witnessed-sign-modal', '')
              if (!response.data.data.pdfs.length) {
                this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
              }
              // this.$swal({
              //   title: 'Signature Saved',
              //   html: response.data.message || '',
              //   // eslint-disable-next-line global-require
              //   imageUrl: require('@/assets/images/icons/save.png'),
              //   imageWidth: 80,
              //   imageHeight: 80,
              //   imageAlt: 'Custom Icon',
              //   showCancelButton: false,
              //   confirmButtonText: 'Okay',
              //   customClass: {
              //     confirmButton: 'btn btn-primary',
              //   },
              //   buttonsStyling: false,
              // })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    openSignatureModal(field) {
      this.currentField = field
      this.$root.$emit('bv::show::modal', 'name-field-modal', '')
    },
    openSecondSignatureModal(field) {
      this.currentSecondField = field
      this.$root.$emit('bv::show::modal', 'second-name-field-modal', '')
    },
    closeNameFieldModal() {
      this.$root.$emit('bv::hide::modal', 'name-field-modal', '')
      const prop = `${this.currentField}InputImage`
      const field = `${this.currentField}InputType`
      if (!this[prop]) {
        this[field] = 'input'
      }
    },
    closeSecondNameFieldModal() {
      this.$root.$emit('bv::hide::modal', 'second-name-field-modal', '')
      const prop = `${this.currentSecondField}InputImageSecond`
      const field = `${this.currentSecondField}InputTypeSecond`
      if (!this[prop]) {
        this[field] = 'input'
      }
    },
    clearNameInputPad() {
      const prop = `${this.currentField}InputImage`
      this[prop] = ''
      this.$refs.nameInputPad.clearSignature()
    },
    clearSecondNameInputPad() {
      const prop = `${this.currentSecondField}InputImageSecond`
      this[prop] = ''
      this.$refs.secondNameInputPad.clearSignature()
    },
    saveNameInput() {
      const { data } = this.$refs.nameInputPad.saveSignature()
      if (data) {
        const prop = `${this.currentField}InputImage`
        const field = `${this.currentField}InputType`
        this[prop] = data
        this.$root.$emit('bv::hide::modal', 'name-field-modal', '')
        this[field] = 'canvas'
      }
    },
    saveSecondNameInput() {
      const { data } = this.$refs.secondNameInputPad.saveSignature()
      if (data) {
        const prop = `${this.currentSecondField}InputImageSecond`
        const field = `${this.currentSecondField}InputTypeSecond`
        this[prop] = data
        this.$root.$emit('bv::hide::modal', 'second-name-field-modal', '')
        this[field] = 'canvas'
      }
    },
    changeCustomerNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.customerNameImage = ''
              this.customerNameType = type
            }
          })
      } else {
        this.customerNameType = type
        this.$root.$emit('bv::show::modal', 'customer-name-modal', '')
      }
    },
    changeKinNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.kinNameImage = ''
              this.currentKinNameType = type
            }
          })
      } else {
        this.currentKinNameType = type
        this.$root.$emit('bv::show::modal', 'kin-name-modal', '')
      }
    },
    closeCustomerNameModal() {
      this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
      if (!this.customerNameImage) {
        this.currentCustomerNameType = 'input'
      }
    },
    closeKinNameModal() {
      this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
      if (!this.kinNameImage) {
        this.currentKinNameType = 'input'
      }
    },
    clearCustomerName() {
      this.customerNameImage = ''
      this.$refs.customerSignaturePadPReview.clearSignature()
    },
    clearKinName() {
      this.kinNameImage = ''
      this.$refs.kinSignaturePadPReview.clearSignature()
    },
    saveCustomerName() {
      const { data } = this.$refs.customerSignaturePadPReview.saveSignature()
      if (data) {
        this.customerNameImage = data
        this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
        this.currentCustomerNameType = 'canvas'
      }
    },
    saveKinName() {
      const { data } = this.$refs.kinSignaturePadPReview.saveSignature()
      if (data) {
        this.kinNameImage = data
        this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
        this.currentKinNameType = 'canvas'
      }
    },
    submitSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerPhoneVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
            this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')

            this.makeFormValues()
            this.$swal({
              title: 'PDF Generated and Sent to the Customer',
              html: response.data.message || '',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/save.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              showCancelButton: false,
              confirmButtonText: 'Okay',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendSignedForm() {
      if (this.requestForm.pdfs.length) {
        this.$http.get(`operation/service-requests/service-form/${this.requestForm._id}/resend-pdf`, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      }
    },
    closeSubmitFormModal() {
      this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
    },
    clearCanvasInput(index) {
      const prop = `${index}InputImage`
      this[prop] = ''
      const field = `${index}InputType`
      this[field] = 'input'
      // this.$refs.nameInputPad.clearSignature()
    },
    clearSecondCanvasInput(index) {
      const prop = `${index}InputImageSecond`
      this[prop] = ''
      const field = `${index}InputTypeSecond`
      this[field] = 'input'
    },
    saveCanvasInput(index) {
      const prop = `${index}InputImage`
      const reference = `${index}InputPad`
      const { data } = this.$refs[reference].saveSignature()
      this[prop] = data
    },
    saveSecondCanvasInput(index) {
      const prop = `${index}InputImageSecond`
      const reference = `${index}InputSecondPad`
      const { data } = this.$refs[reference].saveSignature()
      this[prop] = data
    },
    async handleClick(field, collapse) {
      await this.$refs[collapse].updateVisible(true)
      this.$refs[field].focus()
      if (this.isMobile()) {
        this.isAncestralSidebarActive = true
        this.$emit('updateAncestralSidebarActive', this.isAncestralSidebarActive)
      }
    },
    async handleClickDateField(field, collapse) {
      await this.$refs[collapse].updateVisible(true)
      this.$refs[field].fp.open()
    },
    hideAncestralSidebar() {
      this.isAncestralSidebarActive = false
      this.$emit('updateAncestralSidebarActive', this.isAncestralSidebarActive)
    },
    changeInputType(index, type) {
      const prop = `${index}InputType`
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.clearCanvasInput(index)
              this[prop] = type
            }
          })
      } else {
        this[prop] = type
        this.currentField = index
        this.$root.$emit('bv::show::modal', 'name-field-modal', '')
      }
    },
    changeSecondInputType(index, type) {
      const prop = `${index}InputTypeSecond`
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.clearSecondCanvasInput(index)
              this[prop] = type
            }
          })
      } else {
        this[prop] = type
        this.currentSecondField = index
        this.$root.$emit('bv::show::modal', 'second-name-field-modal', '')
      }
    },
    makeGenderArray() {
      this.genderValue = [this.gender]
    },
    makeCheckboxToRadio() {
      const lastSelected = this.serviceTypeValueLocal.slice(-1)[0]
      if (lastSelected) {
        this.serviceTypeValueLocal = this.serviceTypeValueLocal.filter(code => code === lastSelected)
      }
    },
    submitSaveForm() {
      this.$refs.serviceRequestAncestralFormSave.validate().then(success => {
        const hasError = false
        // if (this.ownEngraving || this.secondOwnEngraving) {
        //   if (!this.uploadedFiles.length) {
        //     hasError = true
        //     this.showFileUploadError = true
        //   }
        // }
        // if (!this.customerSignImage) {
        //   hasError = true
        // }
        if (success && !hasError) {
          // const files = JSON.stringify(this.uploadedFiles)
          let ownEngraving = false
          const valueArray = []
          const ownEngravingObj = {
            key: 'ownEngraving',
            value: this.ownEngraving,
          }
          valueArray.push(ownEngravingObj)

          const secondOwnEngravingObj = {
            key: 'secondOwnEngraving',
            value: this.secondOwnEngraving,
          }
          valueArray.push(secondOwnEngravingObj)

          if (this.ownEngraving || this.secondOwnEngraving) {
            ownEngraving = true
          } else {
            const firstLivingObject = {
              key: 'firstLiving',
              value: this.firstLiving,
            }
            valueArray.push(firstLivingObject)
            const secondLivingObject = {
              key: 'secondLiving',
              value: this.secondLiving,
            }
            valueArray.push(secondLivingObject)
            const thirdLivingObject = {
              key: 'thirdLiving',
              value: this.thirdLiving,
            }
            valueArray.push(thirdLivingObject)
            const fourthLivingObject = {
              key: 'fourthLiving',
              value: this.fourthLiving,
            }
            valueArray.push(fourthLivingObject)
            const fifthLivingObject = {
              key: 'fifthLiving',
              value: this.fifthLiving,
            }
            valueArray.push(fifthLivingObject)
            const sixthLivingObject = {
              key: 'sixthLiving',
              value: this.sixthLiving,
            }
            valueArray.push(sixthLivingObject)
            const seventhLivingObject = {
              key: 'seventhLiving',
              value: this.seventhLiving,
            }
            valueArray.push(seventhLivingObject)

            const firstInputObject = {
              key: 'firstInput',
            }
            if (this.firstInputType === 'input') {
              firstInputObject.value = this.firstInput
            } else {
              firstInputObject.value = this.firstInputImage
            }
            valueArray.push(firstInputObject)
            const secondInputObject = {
              key: 'secondInput',
            }
            if (this.secondInputType === 'input') {
              secondInputObject.value = this.secondInput
            } else {
              secondInputObject.value = this.secondInputImage
            }
            valueArray.push(secondInputObject)
            const thirdInputObject = {
              key: 'thirdInput',
            }
            if (this.thirdInputType === 'input') {
              thirdInputObject.value = this.thirdInput
            } else {
              thirdInputObject.value = this.thirdInputImage
            }
            valueArray.push(thirdInputObject)
            const fourthInputObject = {
              key: 'fourthInput',
            }
            if (this.fourthInputType === 'input') {
              fourthInputObject.value = this.fourthInput
            } else {
              fourthInputObject.value = this.fourthInputImage
            }
            valueArray.push(fourthInputObject)
            const fifthInputObject = {
              key: 'fifthInput',
            }
            if (this.fifthInputType === 'input') {
              fifthInputObject.value = this.fifthInput
            } else {
              fifthInputObject.value = this.fifthInputImage
            }
            valueArray.push(fifthInputObject)
            const sixthInputObject = {
              key: 'sixthInput',
            }
            if (this.sixthInputType === 'input') {
              sixthInputObject.value = this.sixthInput
            } else {
              sixthInputObject.value = this.sixthInputImage
            }
            valueArray.push(sixthInputObject)
            const seventhInputObject = {
              key: 'seventhInput',
            }
            if (this.seventhInputType === 'input') {
              seventhInputObject.value = this.seventhInput
            } else {
              seventhInputObject.value = this.seventhInputImage
            }
            valueArray.push(seventhInputObject)

            const firstLivingSecondObject = {
              key: 'firstLivingSecond',
              value: this.firstLivingSecond,
            }
            valueArray.push(firstLivingSecondObject)
            const secondLivingSecondObject = {
              key: 'secondLivingSecond',
              value: this.secondLivingSecond,
            }
            valueArray.push(secondLivingSecondObject)
            const thirdLivingSecondObject = {
              key: 'thirdLivingSecond',
              value: this.thirdLivingSecond,
            }
            valueArray.push(thirdLivingSecondObject)
            const fourthLivingSecondObject = {
              key: 'fourthLivingSecond',
              value: this.fourthLivingSecond,
            }
            valueArray.push(fourthLivingSecondObject)
            const fifthLivingSecondObject = {
              key: 'fifthLivingSecond',
              value: this.fifthLivingSecond,
            }
            valueArray.push(fifthLivingSecondObject)
            const sixthLivingSecondObject = {
              key: 'sixthLivingSecond',
              value: this.sixthLivingSecond,
            }
            valueArray.push(sixthLivingSecondObject)

            const firstInputSecondObject = {
              key: 'firstInputSecond',
            }
            if (this.firstInputTypeSecond === 'input') {
              firstInputSecondObject.value = this.firstInputSecond
            } else {
              firstInputSecondObject.value = this.firstInputImageSecond
            }
            valueArray.push(firstInputSecondObject)
            const secondInputSecondObject = {
              key: 'secondInputSecond',
            }
            if (this.secondInputTypeSecond === 'input') {
              secondInputSecondObject.value = this.secondInputSecond
            } else {
              secondInputSecondObject.value = this.secondInputImageSecond
            }
            valueArray.push(secondInputSecondObject)
            const thirdInputSecondObject = {
              key: 'thirdInputSecond',
            }
            if (this.thirdInputTypeSecond === 'input') {
              thirdInputSecondObject.value = this.thirdInputSecond
            } else {
              thirdInputSecondObject.value = this.thirdInputImageSecond
            }
            valueArray.push(thirdInputSecondObject)
            const fourthInputSecondObject = {
              key: 'fourthInputSecond',
            }
            if (this.fourthInputTypeSecond === 'input') {
              fourthInputSecondObject.value = this.fourthInputSecond
            } else {
              fourthInputSecondObject.value = this.fourthInputImageSecond
            }
            valueArray.push(fourthInputSecondObject)
            const fifthInputSecondObject = {
              key: 'fifthInputSecond',
            }
            if (this.fifthInputTypeSecond === 'input') {
              fifthInputSecondObject.value = this.fifthInputSecond
            } else {
              fifthInputSecondObject.value = this.fifthInputImageSecond
            }
            valueArray.push(fifthInputSecondObject)
            const sixthInputSecondObject = {
              key: 'sixthInputSecond',
            }
            if (this.sixthInputTypeSecond === 'input') {
              sixthInputSecondObject.value = this.sixthInputSecond
            } else {
              sixthInputSecondObject.value = this.sixthInputImageSecond
            }
            valueArray.push(sixthInputSecondObject)
          }

          const customerNameObj = {
            key: 'customerName',
          }
          if (this.customerNameType === 'input') {
            customerNameObj.value = this.customerName
          } else {
            customerNameObj.value = this.customerNameImage
          }
          valueArray.push(customerNameObj)

          const customerContactObj = {
            key: 'customerContact',
            value: this.customerContact,
          }
          valueArray.push(customerContactObj)

          const customerNRICObj = {
            key: 'customerNRIC',
            value: this.customerNRIC,
          }
          valueArray.push(customerNRICObj)

          const kinNameObj = {
            key: 'kinName',
          }
          if (this.currentKinNameType === 'input') {
            kinNameObj.value = this.kinName
          } else {
            kinNameObj.value = this.kinNameImage
          }
          valueArray.push(kinNameObj)

          const kinContactObj = {
            key: 'kinContact',
            value: this.kinContact,
          }
          valueArray.push(kinContactObj)

          const kinNRICObj = {
            key: 'kinNRIC',
            value: this.kinNRIC,
          }
          valueArray.push(kinNRICObj)

          const fileNoObj = {
            key: 'fileNo',
            value: this.fileNo,
          }
          valueArray.push(fileNoObj)

          const pedestalNoObj = {
            key: 'pedestalNo',
            value: this.pedestalNo,
          }
          valueArray.push(pedestalNoObj)

          const agentNameObj = {
            key: 'agentName',
            value: this.agentName,
          }
          valueArray.push(agentNameObj)

          const agentContactObj = {
            key: 'agentContact',
            value: this.agentContact,
          }
          valueArray.push(agentContactObj)

          const remarksObj = {
            key: 'remarks',
            value: this.remarks,
          }
          valueArray.push(remarksObj)

          const purchaserSignObj = {
            key: 'purchaserSign',
            value: this.customerSignImage,
          }
          valueArray.push(purchaserSignObj)

          // const installationDateObj = {
          //   key: 'installationDate',
          //   value: this.installationDate,
          // }
          // valueArray.push(installationDateObj)

          // const firstInstallationObj = {
          //   key: 'firstInstallation',
          //   value: this.firstInstallation,
          // }
          // valueArray.push(firstInstallationObj)

          // const subsequentInstallationObj = {
          //   key: 'subsequentInstallation',
          //   value: this.subsequentInstallation,
          // }
          // valueArray.push(subsequentInstallationObj)

          // const subsequentInstallationFeeObj = {
          //   key: 'subsequentInstallationFee',
          //   value: this.subsequentInstallationFee,
          // }
          // valueArray.push(subsequentInstallationFeeObj)

          // const receiptNoObj = {
          //   key: 'receiptNo',
          //   value: this.receiptNo,
          // }
          // valueArray.push(receiptNoObj)

          // if (this.existingWitnessedSignSrcObject.key) {
          //   valueArray.push(this.existingWitnessedSignSrcObject)
          // }
          // if (this.existingAuthorizedSignSrcObject.key) {
          //   valueArray.push(this.existingAuthorizedSignSrcObject)
          // }
          // if (this.existingPurchaserSignObject.key) {
          //   valueArray.push(this.existingPurchaserSignObject)
          // }
          // if (this.existingServiceTypeObject.key) {
          //   valueArray.push(this.existingServiceTypeObject)
          // }
          // if (this.existingOfficeRemarksObject.key) {
          //   valueArray.push(this.existingOfficeRemarksObject)
          // }

          this.$emit('updateFormValidation', this.index, ownEngraving, JSON.stringify(valueArray))
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
  <style>
  .col-form-label span{
    font-size: 14px;
    color: #000 !important;
  }
  .step-mobile__font-adjust{
     font-size: 14px !important;
  }
  </style>
