<template>
  <div>
    <validation-observer
      ref="serviceRequestAncestralFormSave"
    >
      <b-row
        class="content-header v-sticky-sidebar-container service_request_task white-background"
        style="border-radius: 6px; margin-left: 0; margin-right: 0;"
      >
        <!-- Content Left -->
        <b-col
          cols="12"
          md="3"
        >
          <app-collapse
            v-if="formStatus == 'To prepare'"
            accordion
            class="mt-2 service-form-collapse"
            :class="{ 'd-none' : isMobile() }"
          >
            <app-collapse-item
              ref="firstPartRef"
              :is-visible="firstPartCollapse"
              title="家族中堂"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <!-- Content Left -->
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">家族中堂</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="12"
                >
                  <b-form-checkbox
                    v-model="ownEngraving"
                    value="ownEngraving"
                  >
                    <span class="field-label">Upload my own engraving format</span>
                    <br>
                    <span class="field-label">使用自己客制化格式</span>
                  </b-form-checkbox>
                  <b-alert
                    variant="success"
                    class="mt-75"
                    :show="ownEngraving != '' || secondOwnEngraving != ''"
                  >
                    <div class="alert-body d-flex">
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="44"
                        class="my-auto mr-1"
                      />
                      <span>Please upload your engraving format at <strong>step 4</strong> after completing step 3.</span>
                    </div>
                  </b-alert>
                  <hr class="dividerHR">
                </b-col>
                <div
                  style="width: 100%;"
                  :class="{'disabled-div': ownEngraving || secondOwnEngraving}"
                >
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#1</span>
                      <b-form-checkbox
                        v-model="firstLiving"
                        value="firstLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="firstInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#1"
                        vid="D#1F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="firstInput"
                          v-model="firstInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('firstInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': firstInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('first', 'input')"
                        />
                      </div>
                      <img
                        v-if="firstInputImage"
                        :src="firstInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('first')"
                      >
                    </div>

                    <div
                      v-if="firstInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('first', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('first', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#2</span>
                      <b-form-checkbox
                        v-model="secondLiving"
                        value="secondLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="secondInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#2"
                        vid="D#2F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="secondInput"
                          v-model="secondInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('secondInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': secondInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('second', 'input')"
                        />
                      </div>
                      <img
                        v-if="secondInputImage"
                        :src="secondInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('second')"
                      >
                    </div>

                    <div
                      v-if="secondInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('second', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('second', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#3</span>
                      <b-form-checkbox
                        v-model="thirdLiving"
                        value="thirdLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="thirdInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#3"
                        vid="D#3F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="thirdInput"
                          v-model="thirdInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('thirdInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': thirdInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('third', 'input')"
                        />
                      </div>
                      <img
                        v-if="thirdInputImage"
                        :src="thirdInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('third')"
                      >
                    </div>

                    <div
                      v-if="thirdInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('third', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('third', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#4</span>
                      <b-form-checkbox
                        v-model="fourthLiving"
                        value="fourthLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="fourthInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#4"
                        vid="D#4F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="fourthInput"
                          v-model="fourthInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('fourthInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': fourthInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('fourth', 'input')"
                        />
                      </div>
                      <img
                        v-if="fourthInputImage"
                        :src="fourthInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('fourth')"
                      >
                    </div>

                    <div
                      v-if="fourthInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('fourth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('fourth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#5</span>
                      <b-form-checkbox
                        v-model="fifthLiving"
                        value="fifthLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="fifthInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#5"
                        vid="D#5F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="fifthInput"
                          v-model="fifthInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('fifthInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': fifthInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('fifth', 'input')"
                        />
                      </div>
                      <img
                        v-if="fifthInputImage"
                        :src="fifthInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('fifth')"
                      >
                    </div>

                    <div
                      v-if="fifthInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('fifth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('fifth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#6</span>
                      <b-form-checkbox
                        v-model="sixthLiving"
                        value="sixthLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="sixthInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#6"
                        vid="D#6F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="sixthInput"
                          v-model="sixthInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('sixthInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': sixthInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('sixth', 'input')"
                        />
                      </div>
                      <img
                        v-if="sixthInputImage"
                        :src="sixthInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('sixth')"
                      >
                    </div>

                    <div
                      v-if="sixthInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('sixth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('sixth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#7</span>
                      <b-form-checkbox
                        v-model="seventhLiving"
                        value="seventhLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="seventhInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#7"
                        vid="D#7F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="seventhInput"
                          v-model="seventhInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('seventhInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': seventhInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('seventh', 'input')"
                        />
                      </div>
                      <img
                        v-if="seventhInputImage"
                        :src="seventhInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('seventh')"
                      >
                    </div>

                    <div
                      v-if="seventhInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('seventh', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('seventh', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-row>
            </app-collapse-item>
            <app-collapse-item
              ref="secondPartRef"
              :is-visible="secondPartCollapse"
              title="無中堂"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <!-- Content Left -->
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">無中堂</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="12"
                >
                  <b-form-checkbox
                    v-model="ownEngraving"
                    value="ownEngraving"
                  >
                    <span class="field-label">Upload my own engraving format</span>
                    <br>
                    <span class="field-label">使用自己客制化格式</span>
                  </b-form-checkbox>
                  <b-alert
                    variant="success"
                    class="mt-75"
                    :show="ownEngraving != '' || secondOwnEngraving != ''"
                  >
                    <div class="alert-body d-flex">
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="44"
                        class="my-auto mr-1"
                      />
                      <span>Please upload your engraving format at <strong>step 4</strong> after completing step 3.</span>
                    </div>
                  </b-alert>
                  <hr class="dividerHR">
                </b-col>
                <div
                  style="width: 100%;"
                  :class="{'disabled-div': ownEngraving || secondOwnEngraving}"
                >
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#1</span>
                      <b-form-checkbox
                        v-model="firstLivingSecond"
                        value="firstLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="firstInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#1"
                        vid="D#1S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="firstInputSecond"
                          v-model="firstInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('firstInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': firstInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('first', 'input')"
                        />
                      </div>
                      <img
                        v-if="firstInputImageSecond"
                        :src="firstInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('first')"
                      >
                    </div>

                    <div
                      v-if="firstInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('first', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('first', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#2</span>
                      <b-form-checkbox
                        v-model="secondLivingSecond"
                        value="secondLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="secondInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#2"
                        vid="D#2S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="secondInputSecond"
                          v-model="secondInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('secondInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': secondInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('second', 'input')"
                        />
                      </div>
                      <img
                        v-if="secondInputImageSecond"
                        :src="secondInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('second')"
                      >
                    </div>

                    <div
                      v-if="secondInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('second', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('second', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#3</span>
                      <b-form-checkbox
                        v-model="thirdLivingSecond"
                        value="thirdLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="thirdInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#3"
                        vid="D#3S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="thirdInputSecond"
                          v-model="thirdInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('thirdInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': thirdInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('third', 'input')"
                        />
                      </div>
                      <img
                        v-if="thirdInputImageSecond"
                        :src="thirdInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('third')"
                      >
                    </div>

                    <div
                      v-if="thirdInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('third', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('third', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#4</span>
                      <b-form-checkbox
                        v-model="fourthLivingSecond"
                        value="fourthLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="fourthInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#4"
                        vid="D#4S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="fourthInputSecond"
                          v-model="fourthInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('fourthInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': fourthInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('fourth', 'input')"
                        />
                      </div>
                      <img
                        v-if="fourthInputImageSecond"
                        :src="fourthInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('fourth')"
                      >
                    </div>

                    <div
                      v-if="fourthInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('fourth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('fourth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#5</span>
                      <b-form-checkbox
                        v-model="fifthLivingSecond"
                        value="fifthLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="fifthInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#5"
                        vid="D#5S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="fifthInputSecond"
                          v-model="fifthInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('fifthInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': fifthInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('fifth', 'input')"
                        />
                      </div>
                      <img
                        v-if="fifthInputImageSecond"
                        :src="fifthInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('fifth')"
                      >
                    </div>

                    <div
                      v-if="fifthInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('fifth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('fifth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#6</span>
                      <b-form-checkbox
                        v-model="sixthLivingSecond"
                        value="sixthLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="sixthInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#6"
                        vid="D#6S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="sixthInputSecond"
                          v-model="sixthInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('sixthInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': sixthInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('sixth', 'input')"
                        />
                      </div>
                      <img
                        v-if="sixthInputImageSecond"
                        :src="sixthInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('sixth')"
                      >
                    </div>

                    <div
                      v-if="sixthInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('sixth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('sixth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-row>
            </app-collapse-item>
            <app-collapse-item
              ref="purchaserDetailsRef"
              :is-visible="purchaserDetailsCollapse"
              title="Purchaser Details"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <!-- Content Left -->
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">Purchaser Details</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <div>
                    <span class="field-label label-required-star">At the behest of 在购者</span>
                  </div>
                  <b-form-group
                    v-if="customerNameType == 'input'"
                    label=""
                    label-for="input-default"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="At the behest of"
                      vid="DCN"
                      rules="required"
                    >
                      <b-form-input
                        id="input-default"
                        ref="customerName"
                        v-model="customerName"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <div
                    v-else
                    style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                    class="my-75"
                    :class="{ 'rounded-error': customerNameImageError }"
                  >
                    <div class="d-flex justify-content-between">
                      <span>Handwriting 手写</span>
                      <feather-icon
                        icon="XIcon"
                        class="ml-auto"
                        size="16"
                        @click="changeCustomerNameInput('input')"
                      />
                    </div>
                    <img
                      v-if="customerNameImage"
                      v-b-modal.customer-name-modal
                      :src="customerNameImage"
                      class="w-100 signature-image"
                      alt="sign"
                      fluid
                    >
                  </div>

                  <div
                    v-if="customerNameType == 'input'"
                    class="d-flex justify-content-between"
                    @click="changeCustomerNameInput('canvas')"
                  >
                    <span style="color: #104D9D">Use handwriting instead</span>
                    <b-button
                      variant="link"
                      class="pt-0 pr-0"
                    >
                      <feather-icon
                        icon="Edit3Icon"
                        class="ml-auto"
                        size="16"
                      />
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-between"
                    @click="changeCustomerNameInput('input')"
                  >
                    <span style="color: #104D9D">Use keyboard instead</span>
                    <b-button
                      variant="link"
                      class="pt-0 pr-0"
                    >
                      <feather-icon
                        icon="Edit3Icon"
                        class="ml-auto"
                        size="16"
                      />
                    </b-button>
                  </div>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="Contact No. 联络号码"
                    label-for="customerContact"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="customerContact"
                      vid="customerContact"
                      rules=""
                    >
                      <b-form-input
                        id="customerContact"
                        ref="customerContact"
                        v-model="customerContact"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label label-required-star"
                    label="NRIC 身份证号码"
                    label-for="customerNRIC"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="NRIC"
                      vid="customerNRICD"
                      rules="required"
                    >
                      <b-form-input
                        id="customerNRIC"
                        ref="customerNRIC"
                        v-model="customerNRIC"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item
              ref="kinDetailsRef"
              :is-visible="kinDetailsCollapse"
              title="Next of Kin Details"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <!-- Content Left -->
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">Next of Kin Details</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <div>
                    <span class="field-label">Next of Kin Name 或是其至亲</span>
                  </div>
                  <b-form-group
                    v-if="currentKinNameType == 'input'"
                    label=""
                    label-for="input-default"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Next of Kin Name"
                      rules=""
                    >
                      <b-form-input
                        id="input-default"
                        ref="kinName"
                        v-model="kinName"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <div
                    v-else
                    style="border-radius: 5px;
                  border: 1px solid #000; padding: 8px; height: 120px"
                    class="my-75"
                    :class="{ 'rounded-error': kinNameImageError }"
                  >
                    <div class="d-flex justify-content-between">
                      <span>Handwriting 手写</span>
                      <feather-icon
                        icon="XIcon"
                        class="ml-auto"
                        size="16"
                        @click="changeKinNameInput('input')"
                      />
                    </div>
                    <img
                      v-if="kinNameImage"
                      v-b-modal.kin-name-modal
                      :src="kinNameImage"
                      class="w-100 signature-image"
                      alt="sign"
                      fluid
                    >
                  </div>

                  <div
                    v-if="currentKinNameType == 'input'"
                    class="d-flex justify-content-between"
                    @click="changeKinNameInput('canvas')"
                  >
                    <span style="color: #104D9D">Use handwriting instead</span>
                    <b-button
                      variant="link"
                      class="pt-0 pr-0"
                    >
                      <feather-icon
                        icon="Edit3Icon"
                        class="ml-auto"
                        size="16"
                      />
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-between"
                    @click="changeKinNameInput('input')"
                  >
                    <span style="color: #104D9D">Use keyboard instead</span>
                    <b-button
                      variant="link"
                      class="pt-0 pr-0"
                    >
                      <feather-icon
                        icon="Edit3Icon"
                        class="ml-auto"
                        size="16"
                      />
                    </b-button>
                  </div>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="Contact"
                    label-for="kinContact"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="kinContact"
                      vid="kinContact"
                      rules=""
                    >
                      <b-form-input
                        id="kinContact"
                        ref="kinContact"
                        v-model="kinContact"
                        placeholder="Enter"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="NRIC 身份证号码"
                    label-for="kinNRIC"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="NRIC"
                      vid="kinNRIC"
                      rules=""
                    >
                      <b-form-input
                        id="kinNRIC"
                        ref="kinNRIC"
                        v-model="kinNRIC"
                        placeholder="Enter"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item
              ref="othersDetailsRef"
              :is-visible="othersDetailsCollapse"
              title="Others"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <!-- Content Left -->
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">Others</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label label-required-star"
                    label="File No. 文件号码"
                    label-for="fileNo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="File No."
                      vid="fileNoD"
                      rules="required"
                    >
                      <b-form-input
                        id="fileNo"
                        ref="fileNo"
                        v-model="fileNo"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label label-required-star"
                    label="Pedestal No. 牌位编号"
                    label-for="pedestalNo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Pedestal No."
                      vid="pedestalNoD"
                      rules="required"
                    >
                      <b-form-input
                        id="pedestalNo"
                        ref="pedestalNo"
                        v-model="pedestalNo"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label label-required-star"
                    label="Agent Name"
                    label-for="agentName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Agent Name"
                      vid="agentNameD"
                      rules="required"
                    >
                      <b-form-input
                        id="agentName"
                        ref="agentName"
                        v-model="agentName"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="Agent Contact"
                    label-for="agentContact"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="agentContact"
                      vid="agentContact"
                      rules=""
                    >
                      <b-form-input
                        id="agentContact"
                        ref="agentContact"
                        v-model="agentContact"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="Remarks"
                    label-for="remarks"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="remarks"
                      vid="remarks"
                      rules=""
                    >
                      <b-form-textarea
                        id="remarks"
                        ref="remarks"
                        v-model="remarks"
                        rows="3"
                        placeholder="Optional"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>

          <div v-if="!editFormInOtherStatus">
            <b-card
              v-if="requestForm.status == 'Ready to sign'"
              class="mt-2 service-forms-card"
            >
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class=""
                    size="20"
                    style="color: #fff;"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">signing required</span>
                  Ready to Sign?
                </h3>
              </template>
              <b-card-text
                class="mb-2"
              >
                <span>If you're prepared to proceed, please press the button to indicate your agreement to this form.</span>
              </b-card-text>

              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                @click="startSignProcess"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Sign now</span>
              </b-button>
            </b-card>
            <b-card
              v-if="requestForm.status == 'Signing'"
              class="mt-2 service-forms-card"
            >
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class=""
                    size="20"
                    style="color: #fff;"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">signing required</span>
                  Start signing
                </h3>
              </template>
              <b-card-text
                class="mb-2"
              >
                <span>This form requires 3 signatures from:</span>
              </b-card-text>

              <app-timeline class="sign-list">
                <app-timeline-item
                  v-if="isPurchaserSign"
                  title="Purchaser / Authorized Representative Signatory & Date"
                  icon="CheckIcon"
                  variant="primary"
                  class="signed"
                />
                <app-timeline-item
                  v-else
                  title="Purchaser / Authorized Representative Signatory & Date"
                  icon="FeatherIcon"
                  variant="primary"
                />
                <app-timeline-item
                  v-if="completionSign"
                  title="Completion Checked By Signatory & Date"
                  icon="CheckIcon"
                  variant="primary"
                  class="signed"
                />
                <app-timeline-item
                  v-else
                  title="Completion Checked By Signatory & Date"
                  icon="FeatherIcon"
                  variant="primary"
                />
              </app-timeline>

              <hr class="dividerHR">

              <b-card-text
                class="mt-2"
              >
                <span>After submitted the signed form, the system will generate a PDF copy and email to customer.</span>
              </b-card-text>
            </b-card>
          </div>
        </b-col>

        <b-col
          :md="isMobile() ? 12 : 9"
          cols="12"
        >
          <!-- <div v-if="ownEngraving || secondOwnEngraving">
            <b-card
              class="my-2 invoice-preview-card"
            >
              <b-row class="invoice-preview">
                <b-col
                  cols="12"
                  xl="2"
                  md="2"
                  class="text-left"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == 1"
                    @click="changeFileIndex(currentFileIndex - 1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="pt-25"
                      size="18"
                    />
                    <span class="align-middle">Prev</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  xl="8"
                  md="8"
                  class="text-center"
                >
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    split
                  >
                    <template #button-content>
                      <feather-icon
                        icon="FileIcon"
                        size="18"
                        class="align-middle mr-1"
                      />
                      <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ ownEngravingImage ? ((ownEngravingImage.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                    </template>
                    <b-dropdown-item @click="changeFileIndex(1)">
                      Ancestral Tablets Engraving Notice 牌位刻碑表格
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="(file, key) in ownEngravingImage"
                      :key="key"
                      @click="changeFileIndex(key + 2)"
                    >
                      {{ file ? file.name : '' }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
                <b-col
                  cols="12"
                  xl="2"
                  md="2"
                  class="text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == (ownEngravingImage ? (ownEngravingImage.length + 1) : 1)"
                    @click="changeFileIndex(currentFileIndex + 1)"
                  >
                    <span class="align-middle">Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="pt-25"
                      size="18"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <b-card
            v-show="showAttachment"
            no-body
            class="invoice-preview-card background-card"
          >
            <div class="m-2 card__inner p-4">
              <iframe
                v-show="showPDF"
                id="attachmentpdf"
                width="100%"
                height="1145"
              />
              <b-img
                v-show="!showPDF"
                :src="imageSrc"
                fluid-grow
                alt="Fluid-grow image"
              />
            </div>
          </b-card> -->
          <b-card
            no-body
            class="invoice-preview-card ancestral-engraving-card my-1"
          >
            <div
              class="urn-form-wrapper urn-form-input m-2"
            >
              <div class="d-form-header d-flex justify-content-between mb-75">
                <div class="d-form-logo_section" />
                <div class="d-form-no_section text-right">
                  <h5>
                    {{ digitalForm.formNumber }}
                  </h5>
                  <h5>
                    {{ digitalForm.revision }}
                  </h5>
                </div>
              </div>
              <div class="urn-repository-order_section">
                <div class="b-form-section-title my-2">
                  <h3>ANCESTRAL TABLETS ENGRAVING NOTICE <span>牌位刻碑表格</span></h3>
                </div>
                <b-row>
                  <b-col
                    lg="8"
                    md="8"
                    sm="8"
                    cols="12"
                    class="pr-0"
                    :class="{'disabled-div': ownEngraving || secondOwnEngraving}"
                  >
                    <div class="d-form-border my-2 mobile_res-adjust-margin">
                      <b-row>
                        <b-col
                          lg="7"
                          md="7"
                          sm="7"
                          cols="12"
                          class="d-flex justify-content-center"
                          :class="{ 'pr-0 an-padding-right-1': isMobile() }"
                        >
                          <div
                            class="p-1 padding-none padding-10"
                            :class="{'ml-1': !isMobile()}"
                          >
                            <h6 class="ancestral-table-section-title">
                              家族中堂
                            </h6>
                            <div class="d-flex">
                              <div class="vertical-single-box-wrapper">
                                <div
                                  class="vertical-single-box"
                                  :class="[((secondInput || secondInputImage) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-adjust' : '')]"
                                >
                                  <div
                                    v-if="secondInput || secondInputImage"
                                    class="vertical-single-box-content"
                                  >
                                    <p v-if="secondLiving">
                                      长生
                                    </p>
                                    <h5
                                      v-if="secondInputType == 'input'"
                                      ref="secondInputField"
                                      :style="{ 'font-size': getFontSize('secondInputField') }"
                                      :class="{ 'chinese-width': hasChineseText(secondInput) }"
                                      @click="handleClick('secondInput', 'firstPartRef')"
                                    >
                                      {{ secondInput }}
                                    </h5>
                                    <div
                                      v-else
                                      :class="{ 'signed': secondInputImage }"
                                    >
                                      <b-img
                                        v-if="secondInputImage"
                                        :src="secondInputImage"
                                        class="w-100 signature-image"
                                        alt="sign"
                                      />
                                    </div>
                                    <p>2</p>
                                  </div>
                                  <div
                                    v-else
                                    class="full-width-div"
                                    @click="handleClick('secondInput', 'firstPartRef')"
                                  >
                                    <div class="vertical-single-box-content-empty">
                                      2
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="vertical-single-box"
                                  :class="[((fourthInput || fourthInputImage) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-adjust' : '')]"
                                >
                                  <div
                                    v-if="fourthInput || fourthInputImage"
                                    class="vertical-single-box-content"
                                  >
                                    <p v-if="fourthLiving">
                                      长生
                                    </p>
                                    <h5
                                      v-if="fourthInputType == 'input'"
                                      ref="fourthInputField"
                                      :style="{ 'font-size': getFontSize('fourthInputField') }"
                                      :class="{ 'chinese-width': hasChineseText(fourthInput) }"
                                      @click="handleClick('fourthInput', 'firstPartRef')"
                                    >
                                      {{ fourthInput }}
                                    </h5>
                                    <div
                                      v-else
                                      :class="{ 'signed': fourthInputImage }"
                                    >
                                      <b-img
                                        v-if="fourthInputImage"
                                        :src="fourthInputImage"
                                        class="w-100 signature-image"
                                        alt="sign"
                                      />
                                    </div>
                                    <p>4</p>
                                  </div>
                                  <div
                                    v-else
                                    class="full-width-div"
                                    @click="handleClick('fourthInput', 'firstPartRef')"
                                  >
                                    <div class="vertical-single-box-content-empty">
                                      4
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="vertical-single-box"
                                  :class="[((sixthInput || sixthInputImage) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-adjust' : '')]"
                                >
                                  <div
                                    v-if="sixthInput || sixthInputImage"
                                    class="vertical-single-box-content"
                                  >
                                    <p v-if="sixthLiving">
                                      长生
                                    </p>
                                    <h5
                                      v-if="sixthInputType == 'input'"
                                      ref="sixthInputField"
                                      :style="{ 'font-size': getFontSize('sixthInputField') }"
                                      :class="{ 'chinese-width': hasChineseText(sixthInput) }"
                                      @click="handleClick('sixthInput', 'firstPartRef')"
                                    >
                                      {{ sixthInput }}
                                    </h5>
                                    <div
                                      v-else
                                      :class="{ 'signed': sixthInputImage }"
                                    >
                                      <b-img
                                        v-if="sixthInputImage"
                                        :src="sixthInputImage"
                                        class="w-100 signature-image"
                                        alt="sign"
                                      />
                                    </div>
                                    <p>6</p>
                                  </div>
                                  <div
                                    v-else
                                    class="full-width-div"
                                    @click="handleClick('sixthInput', 'firstPartRef')"
                                  >
                                    <div class="vertical-single-box-content-empty">
                                      6
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="vertical-single-box-wrapper-with-text">
                                <div class="vertical-single-box-heading">
                                  <h4>佛光</h4>
                                  <h4>接引</h4>
                                </div>
                                <div
                                  class="vertical-single-box-with-text"
                                  :class="[((seventhInput || seventhInputImage) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-adjust' : '')]"
                                >
                                  <div
                                    v-if="seventhInput || seventhInputImage"
                                    class="vertical-single-box-content"
                                  >
                                    <p v-if="seventhLiving">
                                      长生
                                    </p>
                                    <h5
                                      v-if="seventhInputType == 'input'"
                                      ref="seventhInputField"
                                      :style="{ 'font-size': getFontSize('seventhInputField') }"
                                      :class="{ 'chinese-width': hasChineseText(seventhInput) }"
                                      @click="handleClick('seventhInput', 'firstPartRef')"
                                    >
                                      {{ seventhInput }}
                                    </h5>
                                    <div
                                      v-else
                                      :class="{ 'signed': seventhInputImage }"
                                    >
                                      <b-img
                                        v-if="seventhInputImage"
                                        :src="seventhInputImage"
                                        class="w-100 signature-image"
                                        alt="sign"
                                      />
                                    </div>
                                    <p>7</p>
                                  </div>
                                  <div
                                    v-else
                                    class="full-width-div"
                                    @click="handleClick('seventhInput', 'firstPartRef')"
                                  >
                                    <div class="vertical-single-box-content-empty">
                                      7
                                    </div>
                                  </div>
                                </div>
                                <div class="vertical-single-box-heading-bottom">
                                  <h4>門</h4>
                                  <h4>歷</h4>
                                  <h4>代</h4>
                                  <h4>祖</h4>
                                  <h4>先</h4>
                                  <h4>位</h4>
                                </div>
                              </div>
                              <div class="vertical-single-box-wrapper">
                                <div
                                  class="vertical-single-box"
                                  :class="[((firstInput || firstInputImage) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-adjust' : '')]"
                                >
                                  <div
                                    v-if="firstInput || firstInputImage"
                                    class="vertical-single-box-content"
                                  >
                                    <p v-if="firstLiving">
                                      长生
                                    </p>
                                    <h5
                                      v-if="firstInputType == 'input'"
                                      ref="firstInputField"
                                      :style="{ 'font-size': getFontSize('firstInputField') }"
                                      :class="{ 'chinese-width': hasChineseText(firstInput) }"
                                      @click="handleClick('firstInput', 'firstPartRef')"
                                    >
                                      {{ firstInput }}
                                    </h5>
                                    <div
                                      v-else
                                      :class="{ 'signed': firstInputImage }"
                                    >
                                      <b-img
                                        v-if="firstInputImage"
                                        :src="firstInputImage"
                                        class="w-100 signature-image"
                                        alt="sign"
                                      />
                                    </div>
                                    <p>1</p>
                                  </div>
                                  <div
                                    v-else
                                    class="full-width-div"
                                    @click="handleClick('firstInput', 'firstPartRef')"
                                  >
                                    <div class="vertical-single-box-content-empty">
                                      1
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="vertical-single-box"
                                  :class="[((thirdInput || thirdInputImage) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-adjust' : '')]"
                                >
                                  <div
                                    v-if="thirdInput || thirdInputImage"
                                    class="vertical-single-box-content"
                                  >
                                    <p v-if="thirdLiving">
                                      长生
                                    </p>
                                    <h5
                                      v-if="thirdInputType == 'input'"
                                      ref="thirdInputField"
                                      :style="{ 'font-size': getFontSize('thirdInputField') }"
                                      :class="{ 'chinese-width': hasChineseText(thirdInput) }"
                                      @click="handleClick('thirdInput', 'firstPartRef')"
                                    >
                                      {{ thirdInput }}
                                    </h5>
                                    <div
                                      v-else
                                      :class="{ 'signed': thirdInputImage }"
                                    >
                                      <b-img
                                        v-if="thirdInputImage"
                                        :src="thirdInputImage"
                                        class="w-100 signature-image"
                                        alt="sign"
                                      />
                                    </div>
                                    <p>3</p>
                                  </div>
                                  <div
                                    v-else
                                    class="full-width-div"
                                    @click="handleClick('thirdInput', 'firstPartRef')"
                                  >
                                    <div class="vertical-single-box-content-empty">
                                      3
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="vertical-single-box"
                                  :class="[((fifthInput || fifthInputImage) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-adjust' : '')]"
                                >
                                  <div
                                    v-if="fifthInput || fifthInputImage"
                                    class="vertical-single-box-content"
                                  >
                                    <p v-if="fifthLiving">
                                      长生
                                    </p>
                                    <h5
                                      v-if="fifthInputType == 'input'"
                                      ref="fifthInputField"
                                      :style="{ 'font-size': getFontSize('fifthInputField') }"
                                      :class="{ 'chinese-width': hasChineseText(fifthInput) }"
                                      @click="handleClick('fifthInput', 'firstPartRef')"
                                    >
                                      {{ fifthInput }}
                                    </h5>
                                    <div
                                      v-else
                                      :class="{ 'signed': fifthInputImage }"
                                    >
                                      <b-img
                                        v-if="fifthInputImage"
                                        :src="fifthInputImage"
                                        class="w-100 signature-image"
                                        alt="sign"
                                      />
                                    </div>
                                    <p>5</p>
                                  </div>
                                  <div
                                    v-else
                                    class="full-width-div"
                                    @click="handleClick('fifthInput', 'firstPartRef')"
                                  >
                                    <div class="vertical-single-box-content-empty">
                                      5
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col
                          class="vertical-box-right-section-block left-padding-adjust d-flex justify-content-center"
                          lg="5"
                          md="5"
                          sm="5"
                          cols="12"
                        >
                          <div class="p-1 padding-none padding-10 vertical-box-right-section__border-top">
                            <h6 class="ancestral-table-section-title">
                              無中堂
                            </h6>
                            <div class="vertical-single-box-right-section-block">
                              <div class="vertical-single-box-right-section-heading">
                                <h4>佛光</h4>
                                <h4>接引</h4>
                              </div>
                              <div class="vertical-single-box-right-section-main-wrapper">
                                <div class="vertical-single-box-right-section-wrapper">
                                  <div
                                    class="vertical-single-box-right-section"
                                    :class="[((secondInputSecond || secondInputImageSecond) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-right-section-adjust' : '')]"
                                  >
                                    <div
                                      v-if="secondInputSecond || secondInputImageSecond"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="secondLivingSecond">
                                        长生
                                      </p>
                                      <h5
                                        v-if="secondInputTypeSecond == 'input'"
                                        ref="secondInputFieldSecond"
                                        :style="{ 'font-size': getFontSize('secondInputFieldSecond') }"
                                        :class="{ 'chinese-width': hasChineseText(secondInputSecond) }"
                                        @click="handleClick('secondInputSecond', 'secondPartRef')"
                                      >
                                        {{ secondInputSecond }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': secondInputImageSecond }"
                                      >
                                        <b-img
                                          v-if="secondInputImageSecond"
                                          :src="secondInputImageSecond"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>2</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('secondInputSecond', 'secondPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        2
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="vertical-single-box-right-section"
                                    :class="[((fourthInputSecond || fourthInputImageSecond) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-right-section-adjust' : '')]"
                                  >
                                    <div
                                      v-if="fourthInputSecond || fourthInputImageSecond"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="fourthLivingSecond">
                                        长生
                                      </p>
                                      <h5
                                        v-if="fourthInputTypeSecond == 'input'"
                                        ref="fourthInputFieldSecond"
                                        :style="{ 'font-size': getFontSize('fourthInputFieldSecond') }"
                                        :class="{ 'chinese-width': hasChineseText(fourthInputSecond) }"
                                        @click="handleClick('fourthInputSecond', 'secondPartRef')"
                                      >
                                        {{ fourthInputSecond }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': fourthInputImageSecond }"
                                      >
                                        <b-img
                                          v-if="fourthInputImageSecond"
                                          :src="fourthInputImageSecond"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>4</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('fourthInputSecond', 'secondPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        4
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="vertical-single-box-right-section"
                                    :class="[((sixthInputSecond || sixthInputImageSecond) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-right-section-adjust' : '')]"
                                  >
                                    <div
                                      v-if="sixthInputSecond || sixthInputImageSecond"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="sixthLivingSecond">
                                        长生
                                      </p>
                                      <h5
                                        v-if="sixthInputTypeSecond == 'input'"
                                        ref="sixthInputFieldSecond"
                                        :style="{ 'font-size': getFontSize('sixthInputFieldSecond') }"
                                        :class="{ 'chinese-width': hasChineseText(sixthInputSecond) }"
                                        @click="handleClick('sixthInputSecond', 'secondPartRef')"
                                      >
                                        {{ sixthInputSecond }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': sixthInputImageSecond }"
                                      >
                                        <b-img
                                          v-if="sixthInputImageSecond"
                                          :src="sixthInputImageSecond"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>6</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('sixthInputSecond', 'secondPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        6
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="vertical-single-box-right-section-wrapper">
                                  <div
                                    class="vertical-single-box-right-section"
                                    :class="[((firstInputSecond || firstInputImageSecond) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-right-section-adjust' : '')]"
                                  >
                                    <div
                                      v-if="firstInputSecond || firstInputImageSecond"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="firstLivingSecond">
                                        长生
                                      </p>
                                      <h5
                                        v-if="firstInputTypeSecond == 'input'"
                                        ref="firstInputFieldSecond"
                                        :style="{ 'font-size': getFontSize('firstInputFieldSecond') }"
                                        :class="{ 'chinese-width': hasChineseText(firstInputSecond) }"
                                        @click="handleClick('firstInputSecond', 'secondPartRef')"
                                      >
                                        {{ firstInputSecond }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': firstInputImageSecond }"
                                      >
                                        <b-img
                                          v-if="firstInputImageSecond"
                                          :src="firstInputImageSecond"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>1</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('firstInputSecond', 'secondPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        1
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="vertical-single-box-right-section"
                                    :class="[((thirdInputSecond || thirdInputImageSecond) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-right-section-adjust' : '')]"
                                  >
                                    <div
                                      v-if="thirdInputSecond || thirdInputImageSecond"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="thirdLivingSecond">
                                        长生
                                      </p>
                                      <h5
                                        v-if="thirdInputTypeSecond == 'input'"
                                        ref="thirdInputFieldSecond"
                                        :style="{ 'font-size': getFontSize('thirdInputFieldSecond') }"
                                        :class="{ 'chinese-width': hasChineseText(thirdInputSecond) }"
                                        @click="handleClick('thirdInputSecond', 'secondPartRef')"
                                      >
                                        {{ thirdInputSecond }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': thirdInputImageSecond }"
                                      >
                                        <b-img
                                          v-if="thirdInputImageSecond"
                                          :src="thirdInputImageSecond"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>3</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('thirdInputSecond', 'secondPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        3
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="vertical-single-box-right-section"
                                    :class="[((fifthInputSecond || fifthInputImageSecond) ? 'bg-white' : ''), (ownEngraving ? 'vertical-single-box-right-section-adjust' : '')]"
                                  >
                                    <div
                                      v-if="fifthInputSecond || fifthInputImageSecond"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="fifthLivingSecond">
                                        长生
                                      </p>
                                      <h5
                                        v-if="fifthInputTypeSecond == 'input'"
                                        ref="fifthInputFieldSecond"
                                        :style="{ 'font-size': getFontSize('fifthInputFieldSecond') }"
                                        :class="{ 'chinese-width': hasChineseText(fifthInputSecond) }"
                                        @click="handleClick('fifthInputSecond', 'secondPartRef')"
                                      >
                                        {{ fifthInputSecond }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': fifthInputImageSecond }"
                                      >
                                        <b-img
                                          v-if="fifthInputImageSecond"
                                          :src="fifthInputImageSecond"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>5</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('fifthInputSecond', 'secondPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        5
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="vertical-single-box-right-section-heading-bottom">
                                <h4>之</h4>
                                <h4>蓮</h4>
                                <h4>位</h4>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col
                    lg="4"
                    md="4"
                    sm="4"
                    cols="12"
                  >
                    <div class="d-form-border my-2 mobile_res-adjust-margin">
                      <div class="d-form-photo-status-block">
                        <div class="d-form-heading-block">
                          <h4>
                            Photo Received
                            <br>
                            <span>照片提供</span>
                          </h4>
                        </div>
                        <b-form-group
                          label-class="ancestral-label-adjust"
                          label="Received By 收件:"
                          label-for="input-default"
                        >
                          <b-form-input
                            id="input-default"
                            class="form-custom-input bg-white ancestral-input-adjust"
                            disabled
                          />
                        </b-form-group>
                        <b-form-group
                          label-class="ancestral-label-adjust"
                          label="Date 日期:"
                          label-for="input-default"
                          class="form-group-margin"
                        >
                          <b-form-input
                            id="input-default"
                            class="form-custom-input bg-white ancestral-input-adjust"
                            disabled
                          />
                        </b-form-group>
                        <div class="d-form-heading-block">
                          <h4>
                            Photo Returned
                            <br>
                            <span>照片归还</span>
                          </h4>
                        </div>
                        <b-form-group
                          label-class="ancestral-label-adjust"
                          label="Claimed By 照片领回:"
                          label-for="input-default"
                        >
                          <b-form-input
                            id="input-default"
                            class="form-custom-input bg-white ancestral-input-adjust"
                            disabled
                          />
                        </b-form-group>
                        <b-form-group
                          label-class="ancestral-label-adjust"
                          label="Date 日期:"
                          label-for="input-default"
                          class="form-group-margin"
                        >
                          <b-form-input
                            id="input-default"
                            class="form-custom-input bg-white ancestral-input-adjust"
                            disabled
                          />
                        </b-form-group>
                        <div class="d-form-heading-block">
                          <h4>Remarks 备注</h4>
                        </div>
                        <div class="ancestral-form-remarks">
                          <p>All Chinese Character on the plaque must be in traditional Chinese text.<br><span>碑文均採繁體</span></p>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    sm="12"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>Purchaser*</span>
                        <br>
                        <span>购买者</span>
                      </template>
                      <div
                        v-if="customerNameType == 'input'"
                        class="empty-input__text"
                        :class="{ 'bg-white': customerName }"
                        @click="handleClick('customerName', 'purchaserDetailsRef')"
                      >
                        <p
                          v-if="!customerName"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ customerName }}
                        </p>
                      </div>
                      <div
                        v-else
                        :class="{ 'signed': customerNameImage }"
                        style="border-bottom: 1px solid #000"
                      >
                        <b-img
                          v-if="customerNameImage"
                          :src="customerNameImage"
                          class="w-100 signature-image"
                          alt="sign"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>Contact No.</span>
                        <br>
                        <span>联络号码</span>
                      </template>
                      <div
                        class="empty-input__text"
                        :class="{ 'bg-white': customerContact }"
                        @click="handleClick('customerContact', 'purchaserDetailsRef')"
                      >
                        <p
                          v-if="!customerContact"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ customerContact }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>NRIC*</span>
                        <br>
                        <span>身分证号码</span>
                      </template>
                      <div
                        class="empty-input__text"
                        :class="{ 'bg-white': customerNRIC }"
                        @click="handleClick('customerNRIC', 'purchaserDetailsRef')"
                      >
                        <p
                          v-if="!customerNRIC"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ customerNRIC }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col
                    md="12"
                    sm="12"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="3"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span class="label-width-150">Next of Kin Name</span>
                        <br>
                        <span>至亲姓名</span>
                      </template>
                      <div
                        v-if="currentKinNameType == 'input'"
                        class="empty-input__text"
                        :class="{ 'bg-white': kinName }"
                        @click="handleClick('kinName', 'kinDetailsRef')"
                      >
                        <p
                          v-if="!kinName"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ kinName }}
                        </p>
                      </div>
                      <div
                        v-else
                        :class="{ 'signed': kinNameImage }"
                        style="border-bottom: 1px solid #000"
                      >
                        <b-img
                          v-if="kinNameImage"
                          :src="kinNameImage"
                          class="w-100 signature-image"
                          alt="sign"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>Contact No.</span>
                        <br>
                        <span>联络号码</span>
                      </template>
                      <div
                        class="empty-input__text"
                        :class="{ 'bg-white': kinContact }"
                        @click="handleClick('kinContact', 'kinDetailsRef')"
                      >
                        <p
                          v-if="!kinContact"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ kinContact }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>NRIC</span>
                        <br>
                        <span>身分证号码</span>
                      </template>
                      <div
                        class="empty-input__text"
                        :class="{ 'bg-white': kinNRIC }"
                        @click="handleClick('kinNRIC', 'kinDetailsRef')"
                      >
                        <p
                          v-if="!kinNRIC"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ kinNRIC }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>File No.*</span>
                        <br>
                        <span>文件号码</span>
                      </template>
                      <div
                        class="empty-input__text"
                        :class="{ 'bg-white': fileNo }"
                        @click="handleClick('fileNo', 'othersDetailsRef')"
                      >
                        <p
                          v-if="!fileNo"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ fileNo }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>Pedestal No.*</span>
                        <br>
                        <span>牌位编号</span>
                      </template>
                      <div
                        class="empty-input__text"
                        :class="{ 'bg-white': pedestalNo }"
                        @click="handleClick('pedestalNo', 'othersDetailsRef')"
                      >
                        <p
                          v-if="!pedestalNo"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ pedestalNo }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>Agent Name*</span>
                        <br>
                        <span>代理姓名</span>
                      </template>
                      <div
                        class="empty-input__text"
                        :class="{ 'bg-white': agentName }"
                        @click="handleClick('agentName', 'othersDetailsRef')"
                      >
                        <p
                          v-if="!agentName"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ agentName }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="5"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>Agent Contact No.</span>
                        <br>
                        <span>代理联络电话</span>
                      </template>
                      <div
                        class="empty-input__text"
                        :class="{ 'bg-white': agentContact }"
                        @click="handleClick('agentContact', 'othersDetailsRef')"
                      >
                        <p
                          v-if="!agentContact"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ agentContact }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    sm="12"
                    cols="12"
                  >
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-class=""
                      label-for="input-default"
                      class="small-device-f-group-adjust align-items-center"
                    >
                      <template #label>
                        <span>Remarks</span>
                        <br>
                        <span>备注</span>
                      </template>
                      <div
                        class="empty-input__text"
                        :class="{ 'bg-white': remarks }"
                        @click="handleClick('remarks', 'othersDetailsRef')"
                      >
                        <p
                          v-if="!remarks"
                          class="placeholder__text"
                        />
                        <p v-else>
                          {{ remarks }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="d-form-disclaimer_text-block">
                  <p>Disclaimer: I hereby confirm that all the information and characters herein are correct and authorised Mount Prajna Ltd to engrave the Plaque in accordance to this notice. The Plaque will be available after 30 days from the date of this notice.</p>
                  <p>声明： 本人确定所附资料正确无误，并请遵照刻碑，本表格签署三十天后，即请安排上碑。</p>
                </div>
                <b-row class="d-form-signature_block">
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <p>Purchaser’s / Next of Kin’s Signatory & Date
                      <br>
                      <span>购者或至亲签名</span>
                    </p>
                    <div
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.purchaser-sign-modal
                      class="form-signature-box"
                      :class="{ 'signed': isPurchaserSign }"
                      :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                    >
                      <div class="form-signature-content">
                        <span
                          v-if="!isPurchaserSign"
                          class="form-signature-text"
                        >
                          Sign here
                        </span>
                        <b-img
                          v-else
                          :src="customerSignImage"
                          alt="sign"
                        />
                      </div>
                    </div>
                    <div class="signature-date-block">
                      <p>Name 姓名: {{ customerName }}</p>
                      <p v-if="isPurchaserSign">
                        Date 日期: {{ dateFormatWithTime() }}
                      </p>
                      <p v-else>
                        Date 日期:
                      </p>
                    </div>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    cols="12"
                  >
                    <p>Completion checked
                      <br>
                      <span>碑文检查，确认签名 / 日期</span>
                    </p>
                    <div
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="form-signature-box signed"
                    >
                      <div class="form-signature-content" />
                    </div>
                    <div class="signature-date-block">
                      <p>Name 姓名: </p>
                      <p>Date 日期: </p>
                    </div>
                  </b-col>
                </b-row>
                <div class="d-form-border mt-4 mobile_res-bottom-box">
                  <h5 class="d-form-office-use-title">
                    For office use 公务用栏
                  </h5>
                  <b-row>
                    <b-col
                      md="2"
                      sm="4"
                      cols="5"
                      class="d-form-border-right pr-0"
                    >
                      <div class="padding-20">
                        <b-form-group
                          label="Remark 备注:"
                          label-for="input-default"
                          label-class="col-form-label "
                          class="small-device-f-group-adjust"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="serviceType"
                            class="form-custom-input text-capitalize bg-white"
                            disabled
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col
                      md="5"
                      sm="4"
                      cols="7"
                      class="person-in__charge-border"
                      :class="{ 'p-0' : !isMobile(), 'pl-0' : isMobile() }"
                    >
                      <div class="padding-20">
                        <b-form-group
                          label="Person in Charge 服务人员:"
                          label-for="input-default"
                          label-class="col-form-label "
                          class="small-device-f-group-adjust"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="inChargePerson"
                            class="form-custom-input bg-white"
                            disabled
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col
                      md="5"
                      sm="4"
                      cols="12"
                      :class="{ 'pl-0' : !isMobile() }"
                    >
                      <div class="installation-date__border padding-20">
                        <b-form-group
                          label="Installation Date 预定上位日期:"
                          label-for="input-default"
                          label-class="col-form-label "
                          class="small-device-f-group-adjust"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="installationDate"
                            class="form-custom-input bg-white"
                            disabled
                          />
                        </b-form-group>
                        <b-form-group
                          label="Submitted Date 刻碑资料提交日期:"
                          label-for="input-default"
                          label-class="col-form-label "
                          class="small-device-f-group-adjust padding-top-6"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="submitDate"
                            class="form-custom-input bg-white"
                            disabled
                          />
                        </b-form-group>
                        <b-form-group
                          label="Email Date 校对检查电邮日期:"
                          label-for="input-default"
                          label-class="col-form-label "
                          class="small-device-f-group-adjust padding-top-6"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="emailDate"
                            class="form-custom-input bg-white"
                            disabled
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <!-- <b-col
          class="content-header-right my-1 d-md-block"
          :class="{ 'show-error': showFileUploadError }"
          style="position: relative;"
          md="12"
          cols="12"
        >
          <div class="sd-service-wrapper">
            <h5
              class="sd-step-title"
            >
              Step 4 : Upload Photo
            </h5>
            <p class="sd-step__sub-title">
              You can upload:
            </p>
            <ul class="mb-1">
              <li>Deceased’s photo to be included on pedestal (optional)</li>
              <li class="ml-2">
                Please ensure deceased photo are of high quality
              </li>
              <li class="ml-2">
                Images saved from WhatsApp are not typically not recommended
              </li>
              <li>Your own engraving format (for example, hand-written on paper)</li>
            </ul>
          </div>
          <vue-dropzone
            id="event-images"
            ref="myVueDropzone"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            @vdropzone-files-added="processFile"
            @vdropzone-removed-file="fileRemoved"
            @vdropzone-error="handleError"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                Drop file here or click to upload
              </h3>
              <div class="subtitle">
                Files should not be bigger than 20 mb. JPEG, PNG or PDF only.
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mt-2 no-float"
              >
                Upload file
              </b-button>
            </div>
          </vue-dropzone>
        </b-col> -->

        <b-button
          class="my-50 mr-1 ml-auto"
          variant="success"
          @click="submitSaveForm"
        >
          Proceed to next step
        </b-button>
      </b-row>

      <!-- sidebar -->
      <b-sidebar
        id="sidebar-ancestral-service"
        sidebar-class="sidebar-lg"
        :visible="isAncestralSidebarActive"
        bg-variant="white"
        shadow
        :backdrop="false"
        no-header
        :no-enforce-focus="true"
        right
      >
        <div class="sidebar-ancestral-service_header">
          <h5
            class="mb-0"
            @click="hideAncestralSidebar"
          >
            <feather-icon
              class="cursor-pointer"
              icon="ArrowLeftIcon"
              size="16"
            />
            Close 关闭
          </h5>
        </div>

        <div class="sidebar-ancestral-service_body">
          <app-collapse
            v-if="formStatus == 'To prepare'"
            accordion
            class="mt-2 service-form-collapse"
            style="width: 100%;"
          >
            <app-collapse-item
              ref="firstPartRefMob"
              :is-visible="firstPartCollapse"
              title="家族中堂"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">家族中堂</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="12"
                >
                  <b-form-checkbox
                    v-model="ownEngraving"
                    value="ownEngraving"
                  >
                    <span class="field-label">Upload my own engraving format</span>
                    <br>
                    <span class="field-label">使用自己客制化格式</span>
                  </b-form-checkbox>
                  <b-alert
                    variant="success"
                    class="mt-75"
                    :show="ownEngraving != '' || secondOwnEngraving != ''"
                  >
                    <div class="alert-body d-flex">
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="32"
                        class="my-auto mr-1"
                      />
                      <span>Please upload your engraving format at <strong>step 4</strong> after completing step 3.</span>
                    </div>
                  </b-alert>
                  <hr class="dividerHR">
                </b-col>
                <div
                  style="width: 100%;"
                  :class="{'disabled-div': ownEngraving || secondOwnEngraving}"
                >
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#1</span>
                      <b-form-checkbox
                        v-model="firstLiving"
                        value="firstLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="firstInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#1"
                        vid="M#1F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="firstInputMob"
                          v-model="firstInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('firstInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': firstInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('first', 'input')"
                        />
                      </div>
                      <img
                        v-if="firstInputImage"
                        :src="firstInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('first')"
                      >
                    </div>

                    <div
                      v-if="firstInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('first', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('first', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#2</span>
                      <b-form-checkbox
                        v-model="secondLiving"
                        value="secondLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="secondInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#2"
                        vid="M#2F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="secondInputMob"
                          v-model="secondInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('secondInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': secondInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('second', 'input')"
                        />
                      </div>
                      <img
                        v-if="secondInputImage"
                        :src="secondInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('second')"
                      >
                    </div>

                    <div
                      v-if="secondInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('second', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('second', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#3</span>
                      <b-form-checkbox
                        v-model="thirdLiving"
                        value="thirdLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="thirdInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#3"
                        vid="M#3F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="thirdInputMob"
                          v-model="thirdInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('thirdInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': thirdInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('third', 'input')"
                        />
                      </div>
                      <img
                        v-if="thirdInputImage"
                        :src="thirdInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('third')"
                      >
                    </div>

                    <div
                      v-if="thirdInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('third', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('third', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#4</span>
                      <b-form-checkbox
                        v-model="fourthLiving"
                        value="fourthLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="fourthInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#4"
                        vid="M#4F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="fourthInputMob"
                          v-model="fourthInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('fourthInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': fourthInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('fourth', 'input')"
                        />
                      </div>
                      <img
                        v-if="fourthInputImage"
                        :src="fourthInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('fourth')"
                      >
                    </div>

                    <div
                      v-if="fourthInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('fourth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('fourth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#5</span>
                      <b-form-checkbox
                        v-model="fifthLiving"
                        value="fifthLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="fifthInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#5"
                        vid="M#5F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="fifthInputMob"
                          v-model="fifthInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('fifthInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': fifthInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('fifth', 'input')"
                        />
                      </div>
                      <img
                        v-if="fifthInputImage"
                        :src="fifthInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('fifth')"
                      >
                    </div>

                    <div
                      v-if="fifthInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('fifth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('fifth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#6</span>
                      <b-form-checkbox
                        v-model="sixthLiving"
                        value="sixthLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="sixthInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#6"
                        vid="M#6F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="sixthInputMob"
                          v-model="sixthInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('sixthInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': sixthInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('sixth', 'input')"
                        />
                      </div>
                      <img
                        v-if="sixthInputImage"
                        :src="sixthInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('sixth')"
                      >
                    </div>

                    <div
                      v-if="sixthInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('sixth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('sixth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#7</span>
                      <b-form-checkbox
                        v-model="seventhLiving"
                        value="seventhLiving"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="seventhInputType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#7"
                        vid="M#7F"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="seventhInputMob"
                          v-model="seventhInput"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('seventhInputField')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': seventhInputImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeInputType('seventh', 'input')"
                        />
                      </div>
                      <img
                        v-if="seventhInputImage"
                        :src="seventhInputImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSignatureModal('seventh')"
                      >
                    </div>

                    <div
                      v-if="seventhInputType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeInputType('seventh', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeInputType('seventh', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-row>
            </app-collapse-item>
            <app-collapse-item
              ref="secondPartRefMob"
              :is-visible="secondPartCollapse"
              title="無中堂"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">無中堂</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="12"
                >
                  <b-form-checkbox
                    v-model="ownEngraving"
                    value="ownEngraving"
                  >
                    <span class="field-label">Upload my own engraving format</span>
                    <br>
                    <span class="field-label">使用自己客制化格式</span>
                  </b-form-checkbox>
                  <b-alert
                    variant="success"
                    class="mt-75"
                    :show="ownEngraving != '' || secondOwnEngraving != ''"
                  >
                    <div class="alert-body d-flex">
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="32"
                        class="my-auto mr-1"
                      />
                      <span>Please upload your engraving format at <strong>step 4</strong> after completing step 3.</span>
                    </div>
                  </b-alert>
                  <hr class="dividerHR">
                </b-col>
                <div
                  style="width: 100%;"
                  :class="{'disabled-div': ownEngraving || secondOwnEngraving}"
                >
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#1</span>
                      <b-form-checkbox
                        v-model="firstLivingSecond"
                        value="firstLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="firstInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#1"
                        vid="M#1S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="firstInputSecondMob"
                          v-model="firstInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('firstInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': firstInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('first', 'input')"
                        />
                      </div>
                      <img
                        v-if="firstInputImageSecond"
                        :src="firstInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('first')"
                      >
                    </div>

                    <div
                      v-if="firstInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('first', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('first', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#2</span>
                      <b-form-checkbox
                        v-model="secondLivingSecond"
                        value="secondLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="secondInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#2"
                        vid="M#2S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="secondInputSecondMob"
                          v-model="secondInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('secondInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': secondInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('second', 'input')"
                        />
                      </div>
                      <img
                        v-if="secondInputImageSecond"
                        :src="secondInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('second')"
                      >
                    </div>

                    <div
                      v-if="secondInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('second', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('second', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#3</span>
                      <b-form-checkbox
                        v-model="thirdLivingSecond"
                        value="thirdLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="thirdInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#3"
                        vid="M#3S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="thirdInputSecondMob"
                          v-model="thirdInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('thirdInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': thirdInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('third', 'input')"
                        />
                      </div>
                      <img
                        v-if="thirdInputImageSecond"
                        :src="thirdInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('third')"
                      >
                    </div>

                    <div
                      v-if="thirdInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('third', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('third', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#4</span>
                      <b-form-checkbox
                        v-model="fourthLivingSecond"
                        value="fourthLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="fourthInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#4"
                        vid="M#4S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="fourthInputSecondMob"
                          v-model="fourthInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('fourthInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': fourthInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('fourth', 'input')"
                        />
                      </div>
                      <img
                        v-if="fourthInputImageSecond"
                        :src="fourthInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('fourth')"
                      >
                    </div>

                    <div
                      v-if="fourthInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('fourth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('fourth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#5</span>
                      <b-form-checkbox
                        v-model="fifthLivingSecond"
                        value="#5"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="fifthInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#5"
                        vid="M#5S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="fifthInputSecondMob"
                          v-model="fifthInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('fifthInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': fifthInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('fifth', 'input')"
                        />
                      </div>
                      <img
                        v-if="fifthInputImageSecond"
                        :src="fifthInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('fifth')"
                      >
                    </div>

                    <div
                      v-if="fifthInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('fifth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('fifth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left mb-50"
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="field-label">#6</span>
                      <b-form-checkbox
                        v-model="sixthLivingSecond"
                        value="sixthLivingSecond"
                        class="field-label"
                      >
                        长生 (Living)
                      </b-form-checkbox>
                    </div>
                    <b-form-group
                      v-if="sixthInputTypeSecond == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="#6"
                        vid="M#6S"
                        :rules="requiredRuleComputation"
                      >
                        <b-form-textarea
                          id="input-default"
                          ref="sixthInputSecondMob"
                          v-model="sixthInputSecond"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                          @input="resizeText('sixthInputFieldSecond')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': sixthInputImageSecondError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeSecondInputType('sixth', 'input')"
                        />
                      </div>
                      <img
                        v-if="sixthInputImageSecond"
                        :src="sixthInputImageSecond"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                        @click="openSecondSignatureModal('sixth')"
                      >
                    </div>

                    <div
                      v-if="sixthInputTypeSecond == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('sixth', 'canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeSecondInputType('sixth', 'input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-row>
            </app-collapse-item>
            <app-collapse-item
              ref="purchaserDetailsRefMob"
              :is-visible="purchaserDetailsCollapse"
              title="Purchaser Details"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">Purchaser Details</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <div>
                    <span class="field-label label-required-star">At the behest of 在购者</span>
                  </div>
                  <b-form-group
                    v-if="customerNameType == 'input'"
                    label=""
                    label-for="input-default"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="At the behest of"
                      vid="CNM"
                      rules="required"
                    >
                      <b-form-input
                        id="input-default"
                        ref="customerNameMob"
                        v-model="customerName"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <div
                    v-else
                    style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                    class="my-75"
                    :class="{ 'rounded-error': customerNameImageError }"
                  >
                    <div class="d-flex justify-content-between">
                      <span>Handwriting 手写</span>
                      <feather-icon
                        icon="XIcon"
                        class="ml-auto"
                        size="16"
                        @click="changeCustomerNameInput('input')"
                      />
                    </div>
                    <img
                      v-if="customerNameImage"
                      v-b-modal.customer-name-modal
                      :src="customerNameImage"
                      class="w-100 signature-image"
                      alt="sign"
                      fluid
                    >
                  </div>

                  <div
                    v-if="customerNameType == 'input'"
                    class="d-flex justify-content-between"
                    @click="changeCustomerNameInput('canvas')"
                  >
                    <span style="color: #104D9D">Use handwriting instead</span>
                    <b-button
                      variant="link"
                      class="pt-0 pr-0"
                    >
                      <feather-icon
                        icon="Edit3Icon"
                        class="ml-auto"
                        size="16"
                      />
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-between"
                    @click="changeCustomerNameInput('input')"
                  >
                    <span style="color: #104D9D">Use keyboard instead</span>
                    <b-button
                      variant="link"
                      class="pt-0 pr-0"
                    >
                      <feather-icon
                        icon="Edit3Icon"
                        class="ml-auto"
                        size="16"
                      />
                    </b-button>
                  </div>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="Contact No. 联络号码"
                    label-for="customerContact"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="customerContact"
                      vid="customerContact"
                      rules=""
                    >
                      <b-form-input
                        id="customerContact"
                        ref="customerContactMob"
                        v-model="customerContact"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label label-required-star"
                    label="NRIC 身份证号码"
                    label-for="customerNRIC"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="NRIC"
                      vid="customerNRICM"
                      rules="required"
                    >
                      <b-form-input
                        id="customerNRIC"
                        ref="customerNRICMob"
                        v-model="customerNRIC"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item
              ref="kinDetailsRefMob"
              :is-visible="kinDetailsCollapse"
              title="Next of Kin Details"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">Next of Kin Details</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <div>
                    <span class="field-label">Next of Kin Name 或是其至亲</span>
                  </div>
                  <b-form-group
                    v-if="currentKinNameType == 'input'"
                    label=""
                    label-for="input-default"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Next of Kin Name"
                      rules=""
                    >
                      <b-form-input
                        id="input-default"
                        ref="kinNameMob"
                        v-model="kinName"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <div
                    v-else
                    style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                    class="my-75"
                    :class="{ 'rounded-error': kinNameImageError }"
                  >
                    <div class="d-flex justify-content-between">
                      <span>Handwriting 手写</span>
                      <feather-icon
                        icon="XIcon"
                        class="ml-auto"
                        size="16"
                        @click="changeKinNameInput('input')"
                      />
                    </div>
                    <img
                      v-if="kinNameImage"
                      v-b-modal.kin-name-modal
                      :src="kinNameImage"
                      class="w-100 signature-image"
                      alt="sign"
                      fluid
                    >
                  </div>

                  <div
                    v-if="currentKinNameType == 'input'"
                    class="d-flex justify-content-between"
                    @click="changeKinNameInput('canvas')"
                  >
                    <span style="color: #104D9D">Use handwriting instead</span>
                    <b-button
                      variant="link"
                      class="pt-0 pr-0"
                    >
                      <feather-icon
                        icon="Edit3Icon"
                        class="ml-auto"
                        size="16"
                      />
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-between"
                    @click="changeKinNameInput('input')"
                  >
                    <span style="color: #104D9D">Use keyboard instead</span>
                    <b-button
                      variant="link"
                      class="pt-0 pr-0"
                    >
                      <feather-icon
                        icon="Edit3Icon"
                        class="ml-auto"
                        size="16"
                      />
                    </b-button>
                  </div>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="Contact"
                    label-for="kinContact"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="kinContact"
                      vid="kinContact"
                      rules=""
                    >
                      <b-form-input
                        id="kinContact"
                        ref="kinContactMob"
                        v-model="kinContact"
                        placeholder="Enter"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="NRIC 身份证号码"
                    label-for="kinNRIC"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="NRIC"
                      vid="kinNRIC"
                      rules=""
                    >
                      <b-form-input
                        id="kinNRIC"
                        ref="kinNRICMob"
                        v-model="kinNRIC"
                        placeholder="Enter"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item
              ref="othersDetailsRefMob"
              :is-visible="othersDetailsCollapse"
              title="Others"
              class="custom-collapse-icon m-0"
            >
              <template
                #header
              >
                <b-row
                  class="full-width"
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <span style="color: #FFF;">Others</span>
                  </b-col>
                </b-row>
              </template>
              <b-row
                class=""
              >
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label label-required-star"
                    label="File No. 文件号码"
                    label-for="fileNo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="File No."
                      vid="fileNoM"
                      rules="required"
                    >
                      <b-form-input
                        id="fileNo"
                        ref="fileNoMob"
                        v-model="fileNo"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label label-required-star"
                    label="Pedestal No. 牌位编号"
                    label-for="pedestalNo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Pedestal No."
                      vid="pedestalNoM"
                      rules="required"
                    >
                      <b-form-input
                        id="pedestalNo"
                        ref="pedestalNoMob"
                        v-model="pedestalNo"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label label-required-star"
                    label="Agent Name"
                    label-for="agentName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Agent Name"
                      vid="agentNameM"
                      rules="required"
                    >
                      <b-form-input
                        id="agentName"
                        ref="agentNameMob"
                        v-model="agentName"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="Agent Contact"
                    label-for="agentContact"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="agentContact"
                      vid="agentContact"
                      rules=""
                    >
                      <b-form-input
                        id="agentContact"
                        ref="agentContactMob"
                        v-model="agentContact"
                        placeholder="Enter"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="content-header-left mb-50"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-class="field-label"
                    label="Remarks"
                    label-for="remarks"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="remarks"
                      vid="remarks"
                      rules=""
                    >
                      <b-form-textarea
                        id="remarks"
                        ref="remarksMob"
                        v-model="remarks"
                        rows="3"
                        placeholder="Optional"
                        :state="(errors.length > 0) ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-sidebar>
      <!-- ./ sidebar -->
    </validation-observer>

    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser / Authorized Representative Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->

    <!-- completion signature modal -->
    <!-- name modal -->
    <b-modal
      id="name-field-modal"
      ref="name-field-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeNameFieldModal"
    >
      <div class="">
        <VueSignaturePad
          ref="nameInputPad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="nameInputOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearNameInputPad()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveNameInput()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <!-- name modal -->
    <b-modal
      id="second-name-field-modal"
      ref="second-name-field-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeSecondNameFieldModal"
    >
      <div class="">
        <VueSignaturePad
          ref="secondNameInputPad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="secondNameInputOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearSecondNameInputPad()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveSecondNameInput()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name modal -->
    <b-modal
      id="customer-name-modal"
      ref="customer-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeCustomerNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="customerSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="customerNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearCustomerName()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCustomerName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name -->
    <!-- kin name modal -->
    <b-modal
      id="kin-name-modal"
      ref="kin-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeKinNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="kinSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="kinNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearKinName()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveKinName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BImg, BButton, BFormCheckbox, BAlert, BFormTextarea, BSidebar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BFormCheckbox,
    BAlert,
    BFormTextarea,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
    BSidebar,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isAncestralSidebarActive: false,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 20,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
      },
      uploadedFiles: [],
      need: [],
      needOptions: [
        { text: 'As-Need', value: 'as-need' },
        { text: 'Pre-Needed', value: 'pre-needed' },
      ],
      formStatus: 'To prepare',
      isInputValue: true,
      ownEngraving: '',
      secondOwnEngraving: '',
      firstLiving: '',
      secondLiving: '',
      thirdLiving: '',
      fourthLiving: '',
      fifthLiving: '',
      sixthLiving: '',
      seventhLiving: '',
      firstLivingSecond: '',
      secondLivingSecond: '',
      thirdLivingSecond: '',
      fourthLivingSecond: '',
      fifthLivingSecond: '',
      sixthLivingSecond: '',
      firstInputType: 'input',
      secondInputType: 'input',
      thirdInputType: 'input',
      fourthInputType: 'input',
      fifthInputType: 'input',
      sixthInputType: 'input',
      seventhInputType: 'input',
      firstInputTypeSecond: 'input',
      secondInputTypeSecond: 'input',
      thirdInputTypeSecond: 'input',
      fourthInputTypeSecond: 'input',
      fifthInputTypeSecond: 'input',
      sixthInputTypeSecond: 'input',
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: '',
      fifthInput: '',
      sixthInput: '',
      seventhInput: '',
      firstInputSecond: '',
      secondInputSecond: '',
      thirdInputSecond: '',
      fourthInputSecond: '',
      fifthInputSecond: '',
      sixthInputSecond: '',
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      completionSign: false,
      completionSignSrc: '',
      completionSignDate: '',
      completionSignName: '',
      firstInputImageError: false,
      secondInputImageError: false,
      thirdInputImageError: false,
      fourthInputImageError: false,
      fifthInputImageError: false,
      sixthInputImageError: false,
      seventhInputImageError: false,
      firstInputImageSecondError: false,
      secondInputImageSecondError: false,
      thirdInputImageSecondError: false,
      fourthInputImageSecondError: false,
      fifthInputImageSecondError: false,
      sixthInputImageSecondError: false,
      purchaserDetailsCollapse: true,
      kinDetailsCollapse: false,
      othersDetailsCollapse: true,
      firstPartCollapse: true,
      secondPartCollapse: true,
      particularCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      nameInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.nameInputPad.resizeCanvas() },
      },
      secondNameInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.secondNameInputPad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      thirdInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.thirdInputPad.resizeCanvas() },
      },
      fourthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fourthInputPad.resizeCanvas() },
      },
      fifthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fifthInputPad.resizeCanvas() },
      },
      sixthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.sixthInputPad.resizeCanvas() },
      },
      seventhInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.seventhInputPad.resizeCanvas() },
      },
      firstInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.firstInputSecondPad.resizeCanvas() },
      },
      secondInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.secondInputSecondPad.resizeCanvas() },
      },
      thirdInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.thirdInputSecondPad.resizeCanvas() },
      },
      fourthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fourthInputSecondPad.resizeCanvas() },
      },
      fifthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fifthInputSecondPad.resizeCanvas() },
      },
      sixthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.sixthInputSecondPad.resizeCanvas() },
      },
      currentFirstInputType: 'input',
      customerNameType: 'input',
      kinNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNameImageError: false,
      firstInputImage: '',
      secondInputImage: '',
      thirdInputImage: '',
      fourthInputImage: '',
      fifthInputImage: '',
      sixthInputImage: '',
      seventhInputImage: '',
      firstInputImageSecond: '',
      secondInputImageSecond: '',
      thirdInputImageSecond: '',
      fourthInputImageSecond: '',
      fifthInputImageSecond: '',
      sixthInputImageSecond: '',
      customerNRIC: '',
      customerContact: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNameImageError: false,
      kinNRIC: '',
      kinContact: '',
      fileNo: '',
      pedestalNo: '',
      agentName: '',
      agentContact: '',
      remarks: '',
      personInCharge: '',
      installationDate: '',
      installationDateLocal: '',
      inChargePerson: '',
      inChargePersonLocal: '',
      submitDate: '',
      emailDate: '',
      officeRemarks: '',
      serviceType: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      serviceTypeValue: [],
      serviceTypeValueLocal: [],
      existingCompletionSignSrcObject: {},
      existingPurchaserSignObject: {},
      existingServiceTypeObject: {},
      existingInChargePersonObject: {},
      ownEngravingImage: [],
      secondOwnEngravingImage: [],
      selectedFileName: 'Ancestral Tablets Engraving Notice 牌位刻碑表格',
      currentFileIndex: 1,
      imageSrc: '',
      showPDF: true,
      showAttachment: false,
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Compose message',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Compose message',
      },
      isSignature: false,
      defaultSignature: '',
      currentField: '',
      currentSecondField: '',
      customerSignImage: '',
      showFileUploadError: false,
      required,
    }
  },
  computed: {
    requiredRuleComputation() {
      if (this.ownEngraving || this.secondOwnEngraving) {
        return ''
      }
      if (!this.firstInput && !this.secondInput && !this.thirdInput && !this.fourthInput && !this.fifthInput && !this.sixthInput && !this.seventhInput
      && !this.firstInputImage && !this.secondInputImage && !this.thirdInputImage && !this.fourthInputImage && !this.fifthInputImage && !this.sixthInputImage && !this.seventhInputImage
      && !this.firstInputSecond && !this.secondInputSecond && !this.thirdInputSecond && !this.fourthInputSecond && !this.fifthInputSecond && !this.sixthInputSecond
      && !this.firstInputImageSecond && !this.secondInputImageSecond && !this.thirdInputImageSecond && !this.fourthInputImageSecond && !this.fifthInputImageSecond && !this.sixthInputImageSecond) {
        return 'required'
      }

      return ''
    },
    requiredRuleComputationSecond() {
      if (this.ownEngraving || this.secondOwnEngraving) {
        return ''
      }
      if (!this.firstInputSecond && !this.secondInputSecond && !this.thirdInputSecond && !this.fourthInputSecond && !this.fifthInputSecond && !this.sixthInputSecond) {
        return 'required'
      }

      return ''
    },
  },
  created() {
    // this.makeFormValues()
    this.customerMobileVal = this.requestForm.customerContact
    this.customerEmailVal = this.requestForm.customerEmail
    this.customerName = this.requestForm.customerName
    this.customerContact = this.requestForm.customerContact
    this.pedestalNo = this.requestForm.deceasedPedestal
    this.agentName = this.requestForm.agentName
    this.agentContact = this.requestForm.agentContact
  },
  methods: {
    resizeText(field) {
      if (process.env.VUE_APP_FONT_AUTO_ADJUST === 'true') {
        const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight
        let i = 21
        let overflow = true
        const minSize = 2
        const element = this.$refs[field]
        if (element) {
          const parent = element.parentElement

          while (overflow && i > minSize) {
            element.style.fontSize = `${i}px`
            overflow = isOverflown(parent)
            // eslint-disable-next-line no-plusplus
            if (overflow) i--
          }

          element.style.fontSize = `${i}px`
        }
      }
    },
    getFontSize(field) {
      if (process.env.VUE_APP_FONT_AUTO_ADJUST === 'true') {
        const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight
        let i = 21
        let overflow = true
        const minSize = 2
        const element = this.$refs[field]
        if (element) {
          const parent = element.parentElement
          while (overflow && i > minSize) {
            element.style.fontSize = `${i}px`
            overflow = isOverflown(parent)
            // eslint-disable-next-line no-plusplus
            if (overflow) i--
          }
        }

        return `${i}px`
      }

      return '21px'
    },
    hasChineseText(text) {
      const initialLength = text.length
      const chineseLength = text.split('')
        .filter(char => (/\p{Script=Han}/u.test(char) || /\s/u.test(char)))
        .join('')
        .length
      if (initialLength === chineseLength) {
        return true
      }

      return false
    },
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.customerSignImage = data
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        this.isPurchaserSign = true
      }
    },
    openSignatureModal(field) {
      this.currentField = field
      this.$root.$emit('bv::show::modal', 'name-field-modal', '')
    },
    openSecondSignatureModal(field) {
      this.currentSecondField = field
      this.$root.$emit('bv::show::modal', 'second-name-field-modal', '')
    },
    closeNameFieldModal() {
      this.$root.$emit('bv::hide::modal', 'name-field-modal', '')
      const prop = `${this.currentField}InputImage`
      const field = `${this.currentField}InputType`
      if (!this[prop]) {
        this[field] = 'input'
      }
    },
    closeSecondNameFieldModal() {
      this.$root.$emit('bv::hide::modal', 'second-name-field-modal', '')
      const prop = `${this.currentSecondField}InputImageSecond`
      const field = `${this.currentSecondField}InputTypeSecond`
      if (!this[prop]) {
        this[field] = 'input'
      }
    },
    clearNameInputPad() {
      const prop = `${this.currentField}InputImage`
      this[prop] = ''
      this.$refs.nameInputPad.clearSignature()
    },
    clearSecondNameInputPad() {
      const prop = `${this.currentSecondField}InputImageSecond`
      this[prop] = ''
      this.$refs.secondNameInputPad.clearSignature()
    },
    saveNameInput() {
      const { data } = this.$refs.nameInputPad.saveSignature()
      if (data) {
        const prop = `${this.currentField}InputImage`
        const field = `${this.currentField}InputType`
        this[prop] = data
        this.$root.$emit('bv::hide::modal', 'name-field-modal', '')
        this[field] = 'canvas'
      }
    },
    saveSecondNameInput() {
      const { data } = this.$refs.secondNameInputPad.saveSignature()
      if (data) {
        const prop = `${this.currentSecondField}InputImageSecond`
        const field = `${this.currentSecondField}InputTypeSecond`
        this[prop] = data
        this.$root.$emit('bv::hide::modal', 'second-name-field-modal', '')
        this[field] = 'canvas'
      }
    },
    changeCustomerNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.customerNameImage = ''
              this.customerNameType = type
            }
          })
      } else {
        this.customerNameType = type
        this.$root.$emit('bv::show::modal', 'customer-name-modal', '')
      }
    },
    changeKinNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.kinNameImage = ''
              this.currentKinNameType = type
            }
          })
      } else {
        this.currentKinNameType = type
        this.$root.$emit('bv::show::modal', 'kin-name-modal', '')
      }
    },
    closeCustomerNameModal() {
      this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
      if (!this.customerNameImage) {
        this.currentCustomerNameType = 'input'
      }
    },
    closeKinNameModal() {
      this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
      if (!this.kinNameImage) {
        this.currentKinNameType = 'input'
      }
    },
    clearCustomerName() {
      this.customerNameImage = ''
      this.$refs.customerSignaturePad.clearSignature()
    },
    clearKinName() {
      this.kinNameImage = ''
      this.$refs.kinSignaturePad.clearSignature()
    },
    saveCustomerName() {
      const { data } = this.$refs.customerSignaturePad.saveSignature()
      if (data) {
        this.customerNameImage = data
        this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
        this.currentCustomerNameType = 'canvas'
      }
    },
    saveKinName() {
      const { data } = this.$refs.kinSignaturePad.saveSignature()
      if (data) {
        this.kinNameImage = data
        this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
        this.currentKinNameType = 'canvas'
      }
    },
    clearCanvasInput(index) {
      const prop = `${index}InputImage`
      this[prop] = ''
      const field = `${index}InputType`
      this[field] = 'input'
      // this.$refs.nameInputPad.clearSignature()
    },
    clearSecondCanvasInput(index) {
      const prop = `${index}InputImageSecond`
      this[prop] = ''
      const field = `${index}InputTypeSecond`
      this[field] = 'input'
    },
    saveCanvasInput(index) {
      const prop = `${index}InputImage`
      const reference = `${index}InputPad`
      const { data } = this.$refs[reference].saveSignature()
      this[prop] = data
    },
    saveSecondCanvasInput(index) {
      const prop = `${index}InputImageSecond`
      const reference = `${index}InputSecondPad`
      const { data } = this.$refs[reference].saveSignature()
      this[prop] = data
    },
    async handleClick(field, collapse) {
      await this.$refs[collapse].updateVisible(true)
      this.$refs[field].focus()
      if (this.isMobile()) {
        const mobCollapse = `${collapse}Mob`
        const mobField = `${field}Mob`
        await this.$refs[mobCollapse].updateVisible(true)
        this.isAncestralSidebarActive = true
        this.$emit('updateAncestralSidebarActive', this.isAncestralSidebarActive)
        await this.$nextTick()
        this.$refs[mobField].focus()
      }
    },
    async handleClickDateField(field, collapse) {
      await this.$refs[collapse].updateVisible(true)
      this.$refs[field].fp.open()
    },
    hideAncestralSidebar() {
      this.isAncestralSidebarActive = false
      this.$emit('updateAncestralSidebarActive', this.isAncestralSidebarActive)
    },
    changeInputType(index, type) {
      const prop = `${index}InputType`
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.clearCanvasInput(index)
              this[prop] = type
            }
          })
      } else {
        this[prop] = type
        this.currentField = index
        this.$root.$emit('bv::show::modal', 'name-field-modal', '')
      }
    },
    changeSecondInputType(index, type) {
      const prop = `${index}InputTypeSecond`
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.clearSecondCanvasInput(index)
              this[prop] = type
            }
          })
      } else {
        this[prop] = type
        this.currentSecondField = index
        this.$root.$emit('bv::show::modal', 'second-name-field-modal', '')
      }
    },
    makeGenderArray() {
      this.genderValue = [this.gender]
    },
    makeCheckboxToRadio() {
      const lastSelected = this.serviceTypeValueLocal.slice(-1)[0]
      if (lastSelected) {
        this.serviceTypeValueLocal = this.serviceTypeValueLocal.filter(code => code === lastSelected)
      }
    },
    submitSaveForm() {
      this.$refs.serviceRequestAncestralFormSave.validate().then(success => {
        const hasError = false
        // if (this.ownEngraving || this.secondOwnEngraving) {
        //   if (!this.uploadedFiles.length) {
        //     hasError = true
        //     this.showFileUploadError = true
        //   }
        // }
        // if (!this.customerSignImage) {
        //   hasError = true
        // }
        let hasCustomerImage = true
        if (this.customerNameType === 'canvas' && !this.customerNameImage) {
          hasCustomerImage = false
          this.customerNameImageError = true
        }
        if (success && !hasError && hasCustomerImage) {
          // const files = JSON.stringify(this.uploadedFiles)
          let ownEngraving = false
          const valueArray = []
          const ownEngravingObj = {
            key: 'ownEngraving',
            value: this.ownEngraving,
          }
          valueArray.push(ownEngravingObj)

          const secondOwnEngravingObj = {
            key: 'secondOwnEngraving',
            value: this.secondOwnEngraving,
          }
          valueArray.push(secondOwnEngravingObj)

          if (this.ownEngraving || this.secondOwnEngraving) {
            ownEngraving = true
          } else {
            const firstLivingObject = {
              key: 'firstLiving',
              value: this.firstLiving,
            }
            valueArray.push(firstLivingObject)
            const secondLivingObject = {
              key: 'secondLiving',
              value: this.secondLiving,
            }
            valueArray.push(secondLivingObject)
            const thirdLivingObject = {
              key: 'thirdLiving',
              value: this.thirdLiving,
            }
            valueArray.push(thirdLivingObject)
            const fourthLivingObject = {
              key: 'fourthLiving',
              value: this.fourthLiving,
            }
            valueArray.push(fourthLivingObject)
            const fifthLivingObject = {
              key: 'fifthLiving',
              value: this.fifthLiving,
            }
            valueArray.push(fifthLivingObject)
            const sixthLivingObject = {
              key: 'sixthLiving',
              value: this.sixthLiving,
            }
            valueArray.push(sixthLivingObject)
            const seventhLivingObject = {
              key: 'seventhLiving',
              value: this.seventhLiving,
            }
            valueArray.push(seventhLivingObject)

            const firstInputObject = {
              key: 'firstInput',
            }
            if (this.firstInputType === 'input') {
              firstInputObject.value = this.firstInput
            } else {
              firstInputObject.value = this.firstInputImage
            }
            valueArray.push(firstInputObject)
            const secondInputObject = {
              key: 'secondInput',
            }
            if (this.secondInputType === 'input') {
              secondInputObject.value = this.secondInput
            } else {
              secondInputObject.value = this.secondInputImage
            }
            valueArray.push(secondInputObject)
            const thirdInputObject = {
              key: 'thirdInput',
            }
            if (this.thirdInputType === 'input') {
              thirdInputObject.value = this.thirdInput
            } else {
              thirdInputObject.value = this.thirdInputImage
            }
            valueArray.push(thirdInputObject)
            const fourthInputObject = {
              key: 'fourthInput',
            }
            if (this.fourthInputType === 'input') {
              fourthInputObject.value = this.fourthInput
            } else {
              fourthInputObject.value = this.fourthInputImage
            }
            valueArray.push(fourthInputObject)
            const fifthInputObject = {
              key: 'fifthInput',
            }
            if (this.fifthInputType === 'input') {
              fifthInputObject.value = this.fifthInput
            } else {
              fifthInputObject.value = this.fifthInputImage
            }
            valueArray.push(fifthInputObject)
            const sixthInputObject = {
              key: 'sixthInput',
            }
            if (this.sixthInputType === 'input') {
              sixthInputObject.value = this.sixthInput
            } else {
              sixthInputObject.value = this.sixthInputImage
            }
            valueArray.push(sixthInputObject)
            const seventhInputObject = {
              key: 'seventhInput',
            }
            if (this.seventhInputType === 'input') {
              seventhInputObject.value = this.seventhInput
            } else {
              seventhInputObject.value = this.seventhInputImage
            }
            valueArray.push(seventhInputObject)

            const firstLivingSecondObject = {
              key: 'firstLivingSecond',
              value: this.firstLivingSecond,
            }
            valueArray.push(firstLivingSecondObject)
            const secondLivingSecondObject = {
              key: 'secondLivingSecond',
              value: this.secondLivingSecond,
            }
            valueArray.push(secondLivingSecondObject)
            const thirdLivingSecondObject = {
              key: 'thirdLivingSecond',
              value: this.thirdLivingSecond,
            }
            valueArray.push(thirdLivingSecondObject)
            const fourthLivingSecondObject = {
              key: 'fourthLivingSecond',
              value: this.fourthLivingSecond,
            }
            valueArray.push(fourthLivingSecondObject)
            const fifthLivingSecondObject = {
              key: 'fifthLivingSecond',
              value: this.fifthLivingSecond,
            }
            valueArray.push(fifthLivingSecondObject)
            const sixthLivingSecondObject = {
              key: 'sixthLivingSecond',
              value: this.sixthLivingSecond,
            }
            valueArray.push(sixthLivingSecondObject)

            const firstInputSecondObject = {
              key: 'firstInputSecond',
            }
            if (this.firstInputTypeSecond === 'input') {
              firstInputSecondObject.value = this.firstInputSecond
            } else {
              firstInputSecondObject.value = this.firstInputImageSecond
            }
            valueArray.push(firstInputSecondObject)
            const secondInputSecondObject = {
              key: 'secondInputSecond',
            }
            if (this.secondInputTypeSecond === 'input') {
              secondInputSecondObject.value = this.secondInputSecond
            } else {
              secondInputSecondObject.value = this.secondInputImageSecond
            }
            valueArray.push(secondInputSecondObject)
            const thirdInputSecondObject = {
              key: 'thirdInputSecond',
            }
            if (this.thirdInputTypeSecond === 'input') {
              thirdInputSecondObject.value = this.thirdInputSecond
            } else {
              thirdInputSecondObject.value = this.thirdInputImageSecond
            }
            valueArray.push(thirdInputSecondObject)
            const fourthInputSecondObject = {
              key: 'fourthInputSecond',
            }
            if (this.fourthInputTypeSecond === 'input') {
              fourthInputSecondObject.value = this.fourthInputSecond
            } else {
              fourthInputSecondObject.value = this.fourthInputImageSecond
            }
            valueArray.push(fourthInputSecondObject)
            const fifthInputSecondObject = {
              key: 'fifthInputSecond',
            }
            if (this.fifthInputTypeSecond === 'input') {
              fifthInputSecondObject.value = this.fifthInputSecond
            } else {
              fifthInputSecondObject.value = this.fifthInputImageSecond
            }
            valueArray.push(fifthInputSecondObject)
            const sixthInputSecondObject = {
              key: 'sixthInputSecond',
            }
            if (this.sixthInputTypeSecond === 'input') {
              sixthInputSecondObject.value = this.sixthInputSecond
            } else {
              sixthInputSecondObject.value = this.sixthInputImageSecond
            }
            valueArray.push(sixthInputSecondObject)
          }

          const customerNameObj = {
            key: 'customerName',
          }
          if (this.customerNameType === 'input') {
            customerNameObj.value = this.customerName
          } else {
            customerNameObj.value = this.customerNameImage
          }
          valueArray.push(customerNameObj)

          const customerContactObj = {
            key: 'customerContact',
            value: this.customerContact,
          }
          valueArray.push(customerContactObj)

          const customerNRICObj = {
            key: 'customerNRIC',
            value: this.customerNRIC,
          }
          valueArray.push(customerNRICObj)

          const kinNameObj = {
            key: 'kinName',
          }
          if (this.kinNameType === 'input') {
            kinNameObj.value = this.kinName
          } else {
            kinNameObj.value = this.kinNameImage
          }
          valueArray.push(kinNameObj)

          const kinContactObj = {
            key: 'kinContact',
            value: this.kinContact,
          }
          valueArray.push(kinContactObj)

          const kinNRICObj = {
            key: 'kinNRIC',
            value: this.kinNRIC,
          }
          valueArray.push(kinNRICObj)

          const fileNoObj = {
            key: 'fileNo',
            value: this.fileNo,
          }
          valueArray.push(fileNoObj)

          const pedestalNoObj = {
            key: 'pedestalNo',
            value: this.pedestalNo,
          }
          valueArray.push(pedestalNoObj)

          const agentNameObj = {
            key: 'agentName',
            value: this.agentName,
          }
          valueArray.push(agentNameObj)

          const agentContactObj = {
            key: 'agentContact',
            value: this.agentContact,
          }
          valueArray.push(agentContactObj)

          const remarksObj = {
            key: 'remarks',
            value: this.remarks,
          }
          valueArray.push(remarksObj)

          const purchaserSignObj = {
            key: 'purchaserSign',
            value: this.customerSignImage,
          }
          valueArray.push(purchaserSignObj)

          // const installationDateObj = {
          //   key: 'installationDate',
          //   value: this.installationDate,
          // }
          // valueArray.push(installationDateObj)

          // const firstInstallationObj = {
          //   key: 'firstInstallation',
          //   value: this.firstInstallation,
          // }
          // valueArray.push(firstInstallationObj)

          // const subsequentInstallationObj = {
          //   key: 'subsequentInstallation',
          //   value: this.subsequentInstallation,
          // }
          // valueArray.push(subsequentInstallationObj)

          // const subsequentInstallationFeeObj = {
          //   key: 'subsequentInstallationFee',
          //   value: this.subsequentInstallationFee,
          // }
          // valueArray.push(subsequentInstallationFeeObj)

          // const receiptNoObj = {
          //   key: 'receiptNo',
          //   value: this.receiptNo,
          // }
          // valueArray.push(receiptNoObj)

          // if (this.existingWitnessedSignSrcObject.key) {
          //   valueArray.push(this.existingWitnessedSignSrcObject)
          // }
          // if (this.existingAuthorizedSignSrcObject.key) {
          //   valueArray.push(this.existingAuthorizedSignSrcObject)
          // }
          // if (this.existingPurchaserSignObject.key) {
          //   valueArray.push(this.existingPurchaserSignObject)
          // }
          // if (this.existingServiceTypeObject.key) {
          //   valueArray.push(this.existingServiceTypeObject)
          // }
          // if (this.existingOfficeRemarksObject.key) {
          //   valueArray.push(this.existingOfficeRemarksObject)
          // }

          let purchaserSign = false
          if (this.customerSignImage) {
            purchaserSign = true
          }

          this.$emit('updateFormValidation', this.index, ownEngraving, JSON.stringify(valueArray), purchaserSign)
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
<style>
.col-form-label span{
  font-size: 14px;
  color: #000 !important;
}
</style>
